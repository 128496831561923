import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { map } from 'lodash/collection';
import { compose } from 'redux';
import messages from 'containers/ApplicationFormPage/messages';
import { formatBytes } from 'helpers/math';
// import { isEmpty } from 'lodash/lang';
import { groupBy } from 'lodash/collection';
import IconFile from 'images/icon_file.svg';
import IconCamera from 'images/ic_camera.svg';
import IconUpload from 'images/ic_upload.svg';
//component
import CapturePhoto from './CapturePhoto';

//MATERIAL UI
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const isMobile = window.orientation > -1; //check tablet devices

const useStyle = makeStyles(theme => ({
  uploadContainer: {
    // padding: '0 2rem',
  },
  bold: {
    fontWeight: 800,
  },
  uploadItem: {
    paddingBottom: '2rem',
  },
  button: {
    maxWidth: isMobile ? '100%' : '234px',
    minHeight: '95px',
    border: `1px dashed ${theme.palette.primary.light}`,
  },

  uploadButtonIcon: {
    marginRight: '1rem',
  },
  fileContainer: {
    height: '60px',
    padding: '0.7rem',
    border: `2px solid #A0B3D9`,
  },
  fileIcon: {
    height: '40px',
    width: '40px',
    color: theme.palette.primary.main,
  },
}));

function ProofUploadDialog(props) {
  const theme = useTheme();
  const classes = useStyle();
  const intl = useIntl();

  const {
    party,
    handleFileUpload,
    handleDocumentFile,
    handlePhotoFile,
    setDocumentFile,
    file,
    setFile,
    camera,
    setCamera,
    proofDocsRequired,
    ...propList
  } = props;

  const mergedFiles = groupBy(file, 'tag');
  const [tag, setTag] = useState(null);

  return (
    <div className={classes.uploadContainer}>
      {camera ? (
        <CapturePhoto
          file={file}
          setFile={setFile}
          setDocumentFile={e => setDocumentFile(tag, e, true)}
        />
      ) : (
        map(proofDocsRequired, (doc, index) => {
          const key = `proof${index}`;
          return (
            <Grid
              container
              spacing={2}
              className={classes.uploadItem}
              key={key}
            >
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.bold}>
                  {doc.text}:
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                {isMobile ? (
                  <Grid item xs={12}>
                    <input
                      id="selectImage"
                      hidden
                      type="file"
                      onChange={e => handleDocumentFile(tag, e)}
                      onClick={e => {
                        e.target.value = null;
                      }}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      margin="normal"
                      fullWidth
                      id="plus"
                      className={classes.button}
                      onClick={() => {
                        setTag(doc.tag);
                        return handlePhotoFile();
                      }}
                    >
                      <img
                        src={IconCamera}
                        className={classes.uploadButtonIcon}
                        alt=""
                      />
                      <FormattedMessage {...messages.uploadDocumentAndTakePhoto} />
                    </Button>
                  </Grid>
                ) : (
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <input
                        id="selectImage"
                        hidden
                        type="file"
                        onChange={e => handleDocumentFile(tag, e)}
                        onClick={e => {
                          e.target.value = null;
                        }}
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        margin="normal"
                        fullWidth
                        id="plus"
                        className={classes.button}
                        onClick={() => {
                          setTag(doc.tag);
                          return handlePhotoFile();
                        }}
                      >
                        <img
                          src={IconUpload}
                          className={classes.uploadButtonIcon}
                          alt=""
                        />
                        <FormattedMessage {...messages.uploadDocument} />
                      </Button>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                      <input
                        id="captureImage"
                        hidden
                        type="file"
                        accept="image/*"
                        capture="camera"
                        onChange={e => handleDocumentFile(tag, e)}
                      />
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        margin="normal"
                        className={classes.button}
                        onClick={() => {
                          setTag(doc.tag);
                          setCamera(true);
                        }}
                      >
                        <img
                          src={IconCamera}
                          className={classes.uploadButtonIcon}
                          alt=""
                        />
                        <FormattedMessage {...messages.takeAPhoto} />
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {map(mergedFiles[doc.tag], file => {
                return (
                  <Grid item xs={12}>
                    <div className={classes.fileContainer}>
                      <Grid
                        item
                        xs
                        container
                        alignItems="center"
                        justify="center"
                        wrap="nowrap"
                        spacing={1}
                      >
                        <Grid item>
                          <img src={IconFile} className={classes.fileIcon} />
                        </Grid>
                        <Grid item xs>
                          <Typography
                            variant="body2"
                            style={{ fontWeight: 'bold' }}
                          >
                            {file.originalFileName
                              ? file.originalFileName
                              : file.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} container justify="flex-end">
                          <Typography variant="body2">
                            {formatBytes(
                              file.fileSize ? file.fileSize : file.size,
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          );
        })
      )}
    </div>
  );
}

ProofUploadDialog.propTypes = {};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ProofUploadDialog);
