/**
 *
 * DashboardPage
 *
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { useTheme, makeStyles } from '@material-ui/core/styles';

import HubPage from 'containers/HubPage';
import OpsDashboard from 'containers/OpsDashboardPage'
import IllustrationPage from 'containers/IllustrationPage';
import ApplicationPage from 'containers/ApplicationPage';
import InforceIllutrationPage from 'containers/InforceIllutrationPage';
import { resetProductConfigAction } from 'containers/IllustrationPage/actions';
import { logoutAction } from './actions';
import {
  makeSelectSubPage,
  makeSelectSubPageList,
  makeSelectSubPageStepper,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import Sidebar from './components/Sidebar';
import OpsTesting from 'containers/OpsDashboardPage/components/OpsTesting';

const Wrapper = styled(Grid)`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

const useStyles = makeStyles(() => ({}));

export function DashboardPage({
  subPage,
  subPageList,
  subPageStepper,
  logout,
  clearIllustrationData,
}) {
  useInjectReducer({ key: 'dashboardPage', reducer });
  useInjectSaga({ key: 'dashboardPage', saga });
  const theme = useTheme();
  const classes = useStyles();
  const [isOpsLogin,setIsOpsLogin] = React.useState(sessionStorage.getItem('isOps'));

  return (
    <Wrapper>
      {/** ========== Sidebar Component ========= */}
      <Sidebar
        subPage={subPage}
        subPageList={subPageList}
        subPageStepper={subPageStepper}
        logout={logout}
        isOpsLogin={isOpsLogin}
        clearIllustration={() => clearIllustrationData()}
      ></Sidebar>

      {/** =========== End sidebar component ============ */}

      {/** =========== Main Body ============ */}
      <Grid item xs theme={theme} className={classes.scrollBody}>
        <Switch>
          <Route path="/dashboard" exact render={props => isOpsLogin ? <OpsDashboard {...props} /> : <HubPage/> } /> 
          <Route
            path="/illustrations"
            render={props => <IllustrationPage {...props} />}
          />
          <Route
            path="/applications"
            render={props => <ApplicationPage {...props} />}
          />
          <Route
            path="/ifi"
            render={props => <InforceIllutrationPage {...props} />}
          />
          <Route
            path="/adminapplications/:id"
            render={props => <OpsTesting {...props} />}
          />

        </Switch>
      </Grid>
      {/** =========== End Main Body ============ */}
    </Wrapper>
  );
}

DashboardPage.propTypes = {
  subPage: PropTypes.number,
  subPageList: PropTypes.array,
  clearIllustrationData: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  subPage: makeSelectSubPage(),
  subPageList: makeSelectSubPageList(),
  subPageStepper: makeSelectSubPageStepper(),
});

function mapDispatchToProps(dispatch) {
  return {
    logout: value => dispatch(logoutAction(value)),
    clearIllustrationData: () => dispatch(resetProductConfigAction()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DashboardPage);
