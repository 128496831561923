export default {
  api: {
    host: process.env.BACKEND_API_HOST_URL,
    version: process.env.BACKEND_API_VERSION,
    subKey: process.env.BACKEND_API_SUBSCRIPTION_KEY,
  },
  okta: {
    clientId: process.env.OKTA_CLIENT_ID,
    issuer: process.env.OKTA_ISSUER,
    scopes: ['openid', 'profile', 'email', 'phone'],
    redirectUri: `${window.location.origin}/oAuth/callback`,
  },
  payfort: {
    host: process.env.PAYFORT_HOST_URL,
    access_code: process.env.PAYFORT_ACCESS_CODE,
    merchant_identifier: process.env.PAYFORT_MERCHANT_IDENTIFIER,
    sha_req_passphrase: process.env.PAYFORT_SHA_REQ_PASSPHRASE,
    // return_url: `${window.location.origin}/payment/callback`,
    return_url: `${process.env.BACKEND_API_HOST_URL}/api/v1/webhooks/application/payment-callback`,
  },
  analytics: {
    gtmId: process.env.GTM_ID,
  },
};
