/*
 *
 * ApplicationFormPage actions
 *
 */

import {
  DEFAULT_ACTION,
  ANSWER_QUESTION_ACTION,
  SEARCH_ANSWER_ACTION,
  SET_SECTION_QUESTIONS_ACTION,
  CALL_SECTION_QUESTIONS_ACTION,
  SET_SECTION_ACTION,
  SET_SECTION_QUESTIONS_PROPERTY,
  SUBMIT_UNDERWRITING_ACTION,
  UPLOAD_FILE_DOCUMENTS_ACTION,
  ADD_DOCUMENT_ACTION,
  CREATE_IDV_SESSION,
  DELETE_FILE_DOCUMENTS_ACTION,
  SET_PAYMENT_TYPE,
  SET_PAYMENT_DETAIL,
  SUBMIT_PAYMENT_ACTION,
  SUBMIT_CREDIT_CARD_ACTION,
  CONTINUE_PAYMENT_CALLBACK_ACTION,
  SET_PAYMENT_DATA_ACTION,
  GET_CONVERT_CURRENCY_ACTION,
  SET_CONVERT_CURRENCY_ACTION,
  GET_CHANGE_CURRENCY_ACTION,
  SET_CHANGE_CURRENCY_ACTION,
  ADD_DOCTOR_ACTION,
  SET_DOCTOR_LIST_ACTION,
  GET_DOCTORS_ACTION,
  SET_VALID_FUNDS_ACTION,
  GET_VALID_FUNDS_ACTION,
  SET_UW_STATUS,
  REQUEST_SIGNS_ACTION,
  SET_REQUEST_SIGNS_ACTION,
  GET_APPLICATION_ENVELOP_ACTION,
  SET_DOCUMENT_STATUS_ACTION,
  SET_ANSWERS_EMPTY,
  SET_QUESTIONS_EMPTY,
  SEND_PDF_ACTION,
  REFRESH_APPLICATION_ENVELOP_ACTION,
  SET_CONSENT_LINK_ACTION,
  SET_FILE_UW_ACTION,
  SET_COUNT_IDV_SUBMIT,
  SET_EMAIL_ERROR_MESSAGE_ACTION,
  SET_EMAIL_SENT_ACTION,
  UPDATE_APPLICATION_FUND,
  CHECK_NEXUS_DEVERE_FUNDS,
  SET_FUNDS_BELONG_TO_NEXUS_OR_DEVER,
  GENERATE_APPLICATION_PDF,
  GENERATE_CREDIT_CARD_EMAIL,
} from './constants';

// SECTION
export function setCountIdvSubmit(payload) {
  return {
    type: SET_COUNT_IDV_SUBMIT,
    payload,
  };
}

export function setSectionQuestionsAction(payload) {
  return {
    type: SET_SECTION_QUESTIONS_ACTION,
    payload,
  };
}

export function callSectionQuestionsAction(payload) {
  return {
    type: CALL_SECTION_QUESTIONS_ACTION,
    payload,
  };
}

export function setSectionAction(payload) {
  return {
    type: SET_SECTION_ACTION,
    payload,
  };
}

export function setSectionQuestionsProperty(payload) {
  return {
    type: SET_SECTION_QUESTIONS_PROPERTY,
    payload,
  };
}

export function answerQuestionAction(payload) {
  return {
    type: ANSWER_QUESTION_ACTION,
    payload,
  };
}

export function searchAnswerAction(payload) {
  return {
    type: SEARCH_ANSWER_ACTION,
    payload,
  };
}

export function submitUWAction(payload) {
  return {
    type: SUBMIT_UNDERWRITING_ACTION,
    payload,
  };
}

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

// FILE ACTIONS

export function uploadFileDocumentsAction(payload) {
  return {
    type: UPLOAD_FILE_DOCUMENTS_ACTION,
    payload,
  };
}

export function addDocumentAction(payload) {
  return {
    type: ADD_DOCUMENT_ACTION,
    payload,
  };
}

export function deleteFileDocumentsAction(payload) {
  return {
    type: DELETE_FILE_DOCUMENTS_ACTION,
    payload,
  };
}

export function createIDVSessionAction(payload) {
  return {
    type: CREATE_IDV_SESSION,
    payload,
  };
}

export function updatePaymentType(payload) {
  return {
    type: SET_PAYMENT_TYPE,
    payload,
  };
}

export function setPaymentDetail(payload) {
  return {
    type: SET_PAYMENT_DETAIL,
    payload,
  };
}

export function submitPaymentAction(payload) {
  return {
    type: SUBMIT_PAYMENT_ACTION,
    payload,
  };
}

export function submitCreditCardAction(payload) {
  return {
    type: SUBMIT_CREDIT_CARD_ACTION,
    payload,
  };
}

// for nexus admin - to hold the payment and submission to harvest
export function submitForAdminReviewAction(payload) {
  return {
    type: SUBMIT_FOR_ADMIN_REVIEW_ACTION,
    payload,
  };
}

export function getConvertCurrencyAction(payload) {
  return {
    type: GET_CONVERT_CURRENCY_ACTION,
    payload,
  };
}

export function continuePaymentCallbackAction(payfortResponse) {
  return {
    type: CONTINUE_PAYMENT_CALLBACK_ACTION,
    payload: payfortResponse,
  };
}

export function setPaymentDataAction({ status = null }) {
  return {
    type: SET_PAYMENT_DATA_ACTION,
    payload: {
      status,
    },
  };
}

export function setConvertCurrencyAction(payload) {
  return {
    type: SET_CONVERT_CURRENCY_ACTION,
    payload,
  };
}

export function getChangeCurrencyAction(payload) {
  return {
    type: GET_CHANGE_CURRENCY_ACTION,
    payload,
  };
}

export function setChangeCurrencyAction(payload) {
  return {
    type: SET_CHANGE_CURRENCY_ACTION,
    payload,
  };
}
export function addDoctorAction({ data, sessionId, partyId }) {
  return {
    type: ADD_DOCTOR_ACTION,
    data,
    sessionId,
    partyId,
  };
}

export function getDoctorsAction({ sessionId }) {
  return { type: GET_DOCTORS_ACTION, sessionId };
}

export function setDoctorListAction(payload) {
  return {
    type: SET_DOCTOR_LIST_ACTION,
    payload,
  };
}

export function getValidFundssAction(payload) {
  return {
    type: GET_VALID_FUNDS_ACTION,
    payload,
  };
}

export function setValidFundsAction(payload) {
  return {
    type: SET_VALID_FUNDS_ACTION,
    payload,
  };
}

export function setUWStatusAction(payload) {
  return {
    type: SET_UW_STATUS,
    payload,
  };
}

export function requestSignsAction(payload) {
  return {
    type: REQUEST_SIGNS_ACTION,
    payload,
  };
}

export function getApplicationEnvelopAction(payload) {
  return {
    type: GET_APPLICATION_ENVELOP_ACTION,
    payload,
  };
}

export function refreshApplicationEnvelopAction(payload) {
  return {
    type: REFRESH_APPLICATION_ENVELOP_ACTION,
    payload,
  };
}

export function setRequestSignsAction(payload) {
  return {
    type: SET_REQUEST_SIGNS_ACTION,
    payload,
  };
}

export function setDocumentStatusAction(payload) {
  return {
    type: SET_DOCUMENT_STATUS_ACTION,
    payload,
  };
}

export function setAnswersEmptyAction() {
  return {
    type: SET_ANSWERS_EMPTY,
  };
}

export function sendEmailPDFAction(payload) {
  return {
    type: SEND_PDF_ACTION,
    payload,
  };
}

export function setQuestionsEmptyAction() {
  return {
    type: SET_QUESTIONS_EMPTY,
  };
}

export function setConsentLinkAction(payload) {
  return {
    type: SET_CONSENT_LINK_ACTION,
    payload,
  };
}

export function setFileUWAction(payload) {
  return {
    type: SET_FILE_UW_ACTION,
    payload,
  };
}

export function setEmailSentAction(payload) {
  return {
    type: SET_EMAIL_SENT_ACTION,
    payload,
  };
}

export function setEmailErrorMessage(payload) {
  return {
    type: SET_EMAIL_ERROR_MESSAGE_ACTION,
    payload,
  };
}

export function updateApplicationFundsAction(payload) {
  return {
    type: UPDATE_APPLICATION_FUND,
    payload,
  };
}

export function updateApplicationFundsAction2(payload) {
  return {
    type: CHECK_NEXUS_DEVERE_FUNDS,
    payload,
  };
}

export function setFundsBelongToNexusOrDever(payload) {
  return {
    type: SET_FUNDS_BELONG_TO_NEXUS_OR_DEVER,
    payload,
  };
}

export function generateApplicationPdfAction(payload) {
  return {
    type: GENERATE_APPLICATION_PDF,
    payload,
  };
}

export function generateCCEmailAction(payload) {
  return {
    type: GENERATE_CREDIT_CARD_EMAIL,
    payload,
  };
}
