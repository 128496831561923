/*
 * RegisterPage Messages
 *
 * This contains all the text for the RegisterPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.RegisterPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Registration',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First name',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last name',
  },
  username: {
    id: `${scope}.username`,
    defaultMessage: 'ZIO username',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  agencyNo: {
    id: `${scope}.agencyNo`,
    defaultMessage: 'Agency number',
  },
  mobile: {
    id: `${scope}.mobile`,
    defaultMessage: 'Mobile (optional)',
  },
  loginLinkLabel: {
    id: `${scope}.loginLinkLabel`,
    defaultMessage: 'Back to sign in',
  },
  passwordLabel: {
    id: `${scope}.passwordLabel`,
    defaultMessage: 'Enter new password',
  },
  confirmPasswordLabel: {
    id: `${scope}.confirmPasswordLabel`,
    defaultMessage: 'Repeat new password',
  },
  register: {
    id: `${scope}.register`,
    defaultMessage: 'Register',
  },
  phoneFormatError: {
    id: `${scope}.phoneFormatError`,
    defaultMessage: 'Please enter a valid phone number',
  },
  errorUserExisted: {
    id: `${scope}.errorUserExisted`,
    defaultMessage:
      'The email id or username is already in use',
  },
  errorAgentNotFound: {
    id: `${scope}.errorAgentNotFound`,
    defaultMessage: 'Agent is not found. Please try again using other details.',
  },
  errorNotEnoughInformationFromZio: {
    id: `${scope}.errorNotEnoughInformationFromZio`,
    defaultMessage:
      'Please check the credentials provided are valid in order to progress.',
  },
  registrationSuccessNotice: {
    id: `${scope}.registrationSuccessNotice`,
    defaultMessage:
      'You have been successfully registered. Please check your email for the next steps.',
  },
  checkYourEmail: {
    id: `${scope}.checkYourEmail`,
    defaultMessage: 'Check your email',
  },
  weHaveSent: {
    id: `${scope}.weHaveSent`,
    defaultMessage: `We have just sent you an email with a link to complete your
    registration and activate your account.`,
  },
});
