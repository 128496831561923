/**
 *
 * OktaAuthCallback
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useOktaAuth } from '@okta/okta-react';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import LoadingDialog from 'components/LoadingDialog';
import { makeSelectAuth } from 'containers/App/selectors';
import {
  setErrorAction as setAppErrorAction,
  fetchAgentDetailsAction,
} from 'containers/App/actions';
import { useLocation } from 'react-router-dom';
import globalMessages from 'containers/App/messages';
import reducer from './reducer';
import saga from './saga';
import illustrationPageSaga from 'containers/IllustrationPage/saga';
import illustrationPageReducer from 'containers/IllustrationPage/reducer';
import { setOktaTokenAction, setErrorAction } from './actions';
import { makeSelectError } from './selectors';
import { logoutAction } from '../DashboardPage/actions';
export function OktaAuthCallback({
  auth,
  setOktaToken,
  error,
  setError,
  setAppError,
  fetchAgentDetails,
  logout,
}) {
  useInjectReducer({ key: 'oktaAuthCallback', reducer });
  useInjectReducer({
    key: 'illustrationPage',
    reducer: illustrationPageReducer,
  });
  useInjectSaga({ key: 'oktaAuthCallback', saga });
  useInjectSaga({ key: 'illustrationPage', saga: illustrationPageSaga });

  const { oktaAuth } = useOktaAuth();
  const location = useLocation();

  useEffect(() => {
    const oktaHash = queryString.parse(location.hash);
    const params = oktaHash?.state.split(',') || [];
    const quoteId = params[0];
    const agencyId = params[1];
    const productCode = params[2];
    const productVersion = params[3];

    oktaAuth.token
      .getWithoutPrompt()
      .then(({ tokens }) => {
        const { tokenManager } = oktaAuth;
        tokenManager.setTokens(tokens);
        const { accessToken } = tokens;
        setOktaToken({
          oktaAccessToken: accessToken.value,
          productCode,
          productVersion,
          agencyId,
        });
        return oktaAuth.authStateManager.updateAuthState();
      })
      .catch(parseErr => {
        setAppError({
          message: oktaHash?.error_description
            ? {
                id: `OKTA_HASH_ERROR`,
                defaultMessage: oktaHash.error_description,
              }
            : globalMessages.errSomethingWrong,
          isLogout: true,
        });
        setError({
          message: oktaHash?.error_description
            ? {
                id: `OKTA_HASH_ERROR`,
                defaultMessage: oktaHash.error_description,
              }
            : globalMessages.errSomethingWrong,
        });
      });

    return () => {
      // unmount cleanup
      if (auth.agent.length) {
        fetchAgentDetails();
      }
    };
  }, []);

  useEffect(() => {
    const handleLogout = async () => {
      // if (oktaAuth) {
      await logout(oktaAuth);
      // }
    };
    if (error.message && error.isLogout) {
      setTimeout(() => handleLogout(), 2000);
    }
    // return () => {
    //   if (error.message) {
    //     handleLogout();
    //   }
    // };
  }, [error.message]);

  // if (auth.token) {
  //   return <Redirect to="/dashboard" />;
  // }

  // if (error.message) {
  //   return <Redirect to="/login" />;
  // }

  return <LoadingDialog isLoading />;
}

OktaAuthCallback.propTypes = {
  auth: PropTypes.object,
  setOktaToken: PropTypes.func,
  error: PropTypes.object,
  setError: PropTypes.func,
  setAppError: PropTypes.func,
  fetchAgentDetails: PropTypes.func,
  logout: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  auth: makeSelectAuth(),
  error: makeSelectError(),
});

function mapDispatchToProps(dispatch) {
  return {
    setOktaToken: oktaAccessToken =>
      dispatch(setOktaTokenAction(oktaAccessToken)),
    setError: ({ message }) => dispatch(setErrorAction({ message })),
    setAppError: ({ message, isLogout }) =>
      dispatch(setAppErrorAction({ message, isLogout })),
    fetchAgentDetails: () => dispatch(fetchAgentDetailsAction()),
    logout: value => dispatch(logoutAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OktaAuthCallback);
