import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash/lang';
import globalMessages from 'containers/App/messages';
import { isFieldEmpty, isNumberFieldEmpty } from 'utils/form';
import numeral from 'numeral';
import { calculateAgeNextBirthDate } from 'helpers/date';
import { getMinValue, getMaxValue, getQuoteStartDate } from '../../helper';

const validate = (values, props) => {
  const errors = {};
  const policyConfig = props.policyConfig;
  const lifeAssured = props.lifeAssured;
  const initProductConfig = props.initProductConfig;
  console.log(props);
  const harvestPolicyDetails = props.harvestPolicyDetails;
  if (!values.isEmpty()) {
    const lastPaidDate = values.get('lastPaidDate');
    const quoteStartDate = getQuoteStartDate();
    const canDecreasePremium =
      new Date(quoteStartDate).getTime() > new Date(lastPaidDate).getTime();
    const currencyValues = values.get('currency');
    const paymentFrequencyValues = values.get('paymentFrequency');
    const premiumValues = values.get('premium');
    const isEditPremium = values.get('isEditPremium');
    const initPremium = values.get('initPremium');
    const termValues = values.get('term');
    const growthRateValues = values.get('growthRate');
    const singlePremiumValues = values.get('singlePremium');
    const coveragesValues = values.get('coverages');
    const singlePremiumTermValues = values.get('singlePremiumTerm');
    const exisitingPremiumValues = values.get('exisitingPremium');
    const partialWithdrawal = values.get('partialWithdrawal') || 0;
  
    
    

    const MAX_GROWTH_RATE = policyConfig?.agencyNumber === "8910138" && policyConfig?.productCode === "SCFUT" ? 6 : policyConfig?.agencyNumber === '8910138' && policyConfig?.productCode === "SAVP3" ? 7 : policyConfig?.features?.growthRate?.ifi?.maxRate;
    const MIN_GROWTH_RATE = policyConfig?.agencyNumber === "8910138" && policyConfig?.productCode === "SCFUT" ? 6 : policyConfig?.agencyNumber === '8910138' && policyConfig?.productCode === "SAVP3" ? 7 : policyConfig?.features?.growthRate?.ifi.minRate;

    var minPremium = 0;
    var maxPremium = 0;

    const isEditTerm = values.get('isEditTerm');
    const { minTerm, maxTerm } = props;
    if (isEditTerm && termValues < minTerm) {
      errors.term = (
        <FormattedMessage
          {...globalMessages.minLimit}
          values={{ value: minTerm }}
        />
      );
    }

    if (isEditTerm && termValues > maxTerm) {
      errors.term = (
        <FormattedMessage
          {...globalMessages.maxLimit}
          values={{ value: maxTerm }}
        />
      );
    }

    policyConfig?.features?.currencyPremiumSize.map(val => {
      if (currencyValues === val.currency) {
        if (termValues === 'SinglePremium') {
          minPremium = val.minPremiumSingle;
          maxPremium = val.maxPremiumSingle;
        } else {
          switch (paymentFrequencyValues) {
            case 'Monthly':
              minPremium = val.minPremiumMonthly;
              maxPremium = val.maxPremiumMonthly;
              break;

            case 'Quarterly':
              minPremium = val.minPremiumQuarterly;
              maxPremium = val.maxPremiumQuarterly;
              break;

            case 'Half-Yearly':
              minPremium = val.minPremiumHalfYearly;
              maxPremium = val.maxPremiumHalfYearly;
              break;

            case 'Yearly':
              minPremium = val.minPremiumYearly;
              maxPremium = val.maxPremiumYearly;
              break;

            case 'Single':
              minPremium = singlePremiumTermValues > 0 ? initProductConfig.premium >= val.minPremiumSingle ? 0 : parseFloat((val.minPremiumSingle - initProductConfig.premium)/(singlePremiumTermValues - 1)).toFixed(2): val.minPremiumSingle;
              maxPremium = singlePremiumTermValues > 0 ? exisitingPremiumValues : val.maxPremiumSingle;
              break;

            default:
              minPremium = val.minPremiumMonthly;
              maxPremium = val.maxPremiumMonthly;
              break;
          }
        }
      }
    });

    if (!canDecreasePremium) {
      minPremium = initPremium;
    }

    if (isEditPremium) {
      if (!premiumValues && Number(premiumValues) !== 0) {
        errors.premium = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      } else {
        if (Number(premiumValues) < minPremium && premiumValues > 0) {
          errors.premium = (
            <FormattedMessage
              {...globalMessages.minLimit}
              values={{ value: minPremium }}
            />
          );
        }

          if (Number(premiumValues) < 0) {
            errors.premium = (
              <FormattedMessage
                {...globalMessages.minLimit}
                values={{ value: minPremium }}
              />
            );
          }

        if (premiumValues > maxPremium)
          errors.premium = (
            <FormattedMessage
              {...globalMessages.maxLimit}
              values={{ value: maxPremium }}
            />
          );
      }
    }

    if (!growthRateValues) {
      errors.growthRate = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    } else if(MIN_GROWTH_RATE == MAX_GROWTH_RATE) {
      if(growthRateValues != MAX_GROWTH_RATE ) {
        errors.growthRate = (
          <FormattedMessage {...globalMessages.minMaxSame} 
          values={{ value: MAX_GROWTH_RATE }}/>
        );
      }
    } else {
      if (growthRateValues < MIN_GROWTH_RATE)
        errors.growthRate = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: MIN_GROWTH_RATE }}
          />
        );
      if (growthRateValues > MAX_GROWTH_RATE)
        errors.growthRate = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: MAX_GROWTH_RATE }}
          />
        );
    }

    if (!singlePremiumValues && Number(singlePremiumValues) !== 0) {
      errors.singlePremium = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    } else {
      var minSinglePremium = 0;
      var maxSinglePremium = 0;
      policyConfig?.features?.currencyPremiumSize.map(val => {
        if (currencyValues === val.currency) {
          minSinglePremium = val.minAdditionalSinglePremium;
          maxSinglePremium = val.maxAdditionalSinglePremium;
          return;
        }
      });

      if (singlePremiumValues < minSinglePremium && singlePremiumValues > 0) {
        errors.singlePremium = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: minSinglePremium }}
          />
        );
      }

      if (singlePremiumValues < 0) {
        errors.singlePremium = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: minSinglePremium }}
          />
        );
      }
      if (singlePremiumValues > maxSinglePremium) {
        errors.singlePremium = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: maxSinglePremium }}
          />
        );
      }
    }

    if (coveragesValues && coveragesValues.size > 0) {
      const coveragesError = [];
      let lfcInsuredOne = 0;
      let lfcInsuredTwo = 0;
      coveragesValues.map(coverage => {
        const isLifeOneEdit = coverage.get('isLifeOneEdit');
        const isLifeTwoEdit = coverage.get('isLifeTwoEdit');
        // console.log(
        //   'isLifeEdit',
        //   coverage.get('id'),
        //   isLifeOneEdit,
        //   isLifeTwoEdit,
        // );
        const canIncreaseBenefit = {
          valueInsuredOne: true,
          valueInsuredTwo: true,
        };
        const initValue = {
          valueInsuredOne: Number(coverage.get('initValueInsuredOne')),
          valueInsuredTwo: Number(coverage.get('initValueInsuredTwo')),
        };

        const ageOfLifeInsuredOne = calculateAgeNextBirthDate(
          quoteStartDate,
          lifeAssured.insuredOne?.dateOfBirth,
        );
        const ageOfLifeInsuredTwo = calculateAgeNextBirthDate(
          quoteStartDate,
          lifeAssured.insuredTwo?.dateOfBirth,
        );

        if (coverage.get('id') === 'LFC') {
          lfcInsuredOne = coverage.get('valueInsuredOne');
          lfcInsuredTwo = coverage.get('valueInsuredTwo');
        }

        let requiredCoverageFields = [];

        requiredCoverageFields.push('valueInsuredOne');
        requiredCoverageFields.push('termOne');

        if (lifeAssured.isInsuredTwo && lifeAssured.lifeBasis !== 'SL') {
          if (coverage.get('maxEntryAgeLA') != '0') {
            if (coverage.get('maxEntryAgeLA') < ageOfLifeInsuredTwo) {
              canIncreaseBenefit.valueInsuredTwo = false;
            }
          }
          requiredCoverageFields.push('valueInsuredTwo');
          requiredCoverageFields.push('termTwo');
        }

        if (coverage.get('maxEntryAgeLA') < ageOfLifeInsuredOne) {
          canIncreaseBenefit.valueInsuredOne = false;
        }

        const coverageError = {};
        requiredCoverageFields.forEach(field => {
          if (
            (['valueInsuredOne', 'termOne'].includes(field) && isLifeOneEdit) ||
            (['valueInsuredTwo', 'termTwo'].includes(field) && isLifeTwoEdit)
          ) {
            const termOne = numeral(coverage.get('termOne')).value();
            const termTwo = numeral(coverage.get('termTwo')).value();
            const insuredOne = numeral(coverage.get('valueInsuredOne')).value();
            const insuredTwo = numeral(coverage.get('valueInsuredTwo')).value();

            if (['FMI', 'FTI'].includes(coverage.get('id'))) {
              if (
                (isNumberFieldEmpty(termOne) &&
                  !isNumberFieldEmpty(insuredOne)) ||
                termOne === null
              ) {
                coverageError['termOne'] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
              }

              if (
                (!isNumberFieldEmpty(termOne) &&
                  isNumberFieldEmpty(insuredOne)) ||
                insuredOne === null
              ) {
                coverageError['valueInsuredOne'] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
              }
              if (termOne === 0 && insuredOne === 0) {
                delete coverageError['termOne'];
                delete coverageError['valueInsuredOne'];
              }

              if (
                ['termTwo'].includes(field) &&
                ((isNumberFieldEmpty(termTwo) &&
                  !isNumberFieldEmpty(insuredTwo)) ||
                  termTwo === null)
              ) {
                coverageError['termTwo'] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
              }

              if (
                ['valueInsuredTwo'].includes(field) &&
                ((!isNumberFieldEmpty(termTwo) &&
                  isNumberFieldEmpty(insuredTwo)) ||
                  insuredTwo === null)
              ) {
                coverageError['valueInsuredTwo'] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
              }

              if (termTwo === 0 && insuredTwo === 0) {
                delete coverageError['termTwo'];
                delete coverageError['valueInsuredTwo'];
              }

              if (field === 'termOne' || field === 'termTwo') {
                if (!isEmpty(coverage.get(field)) && coverage.get(field) > 40) {
                  coverageError[field] = (
                    <FormattedMessage
                      {...globalMessages.maxValueLimit}
                      values={{ value: '40' }}
                    />
                  );
                }

                if (
                  (!isEmpty(coverage.get(field)) &&
                    coverage.get(field) < 10 &&
                    coverage.get(field) > 0) ||
                  numeral(coverage.get(field)).value() < 0
                ) {
                  coverageError[field] = (
                    <FormattedMessage
                      {...globalMessages.minValueLimit}
                      values={{ value: '10' }}
                    />
                  );
                }
              }
            }

            if (field === 'valueInsuredOne' || field === 'valueInsuredTwo') {
              if (isFieldEmpty(coverage.get(field))) {
                coverageError[field] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
              }

              if (!['WOP', 'ESC'].includes(coverage.get('id'))) {
                // Only validate min value when the changing input.
                let minVal =
                  policyConfig.features &&
                  getMinValue(
                    coverage.get('id'),
                    policyConfig.features,
                    currencyValues,
                  );

                const maxVal =
                  policyConfig.features && !canIncreaseBenefit[field]
                    ? initValue[field]
                    : getMaxValue(
                        coverage.get('id'),
                        policyConfig.features,
                        currencyValues,
                      );

                const isMinValidate =
                  minVal > numeral(coverage.get(field)).value();

                const isMinValidateByCoverage =
                  coverage.get('id') === 'LFC'
                    ? isMinValidate
                    : (isMinValidate &&
                        numeral(coverage.get(field)).value() > 0) ||
                      numeral(coverage.get(field)).value() < 0;

                if (isMinValidateByCoverage) {
                  coverageError[field] = (
                    <FormattedMessage
                      {...globalMessages.minAmountLimit}
                      values={{ value: minVal }}
                    />
                  );
                }

                if (maxVal < numeral(coverage.get(field)).value()) {
                  coverageError[field] = (
                    <FormattedMessage
                      {...globalMessages.maxAmountLimit}
                      values={{ value: maxVal }}
                    />
                  );
                }
              }

              if (
                field === 'valueInsuredOne' &&
                !['FMI', 'FTI'].includes(coverage.get('id')) &&
                numeral(coverage.get(field)).value() >
                  numeral(lfcInsuredOne).value()
              ) {
                coverageError['valueInsuredOne'] = (
                  <FormattedMessage {...globalMessages.accelerateValidation} />
                );
              }

              if (
                field === 'valueInsuredTwo' &&
                !['FMI', 'FTI'].includes(coverage.get('id')) &&
                numeral(coverage.get(field)).value() >
                  numeral(lfcInsuredTwo).value()
              ) {
                coverageError['valueInsuredTwo'] = (
                  <FormattedMessage {...globalMessages.accelerateValidation} />
                );
              }
            }
          }
        });
        coveragesError.push(coverageError);
      });

      if (!isEmpty(coveragesError)) errors.coverages = coveragesError;
    }

    if(!partialWithdrawal && Number(partialWithdrawal) !==0){
      errors.partialWithdrawal = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    } else {
      var minPartialWithdrawal = 0;
      var maxPartialWithdrawal = harvestPolicyDetails?.maxPartialSurrender;
      policyConfig?.features?.partialWithdrawal?.map(val => {
        if (currencyValues === val.currency) {
          minPartialWithdrawal = val.min;
          return;
        }
      });
      if (Number(partialWithdrawal) < minPartialWithdrawal && Number(partialWithdrawal) > 0) {
        errors.partialWithdrawal = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: minPartialWithdrawal }}
          />
        );
      }

      if (Number(partialWithdrawal) < 0) {
        errors.partialWithdrawal = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: minPartialWithdrawal }}
          />
        );
      }
      if (Number(partialWithdrawal) > Number(maxPartialWithdrawal)) {
        errors.partialWithdrawal = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: maxPartialWithdrawal }}
          />
        );
      }
    }
  }

  return errors;
};

export default validate;