import { getImmutableState } from 'helpers/lang';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the applicationFormPage state domain
 */

const selectApplicationFormPageDomain = state =>
  state.get('applicationFormPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by ApplicationFormPage
 */

const makeSelectSectionAnswers = section => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    getImmutableState(substate, ['answers', section]),
  );
};

const makeSelectPaymentType = section => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    substate.get('paymentType'),
  );
};

const makeSelectPaymentDetail = section => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    getImmutableState(substate, ['paymentDetail']),
  );
};

const makeSelectQuestions = section => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    getImmutableState(substate, ['questions']),
  );
};

const makeSelectAnswers = section => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    getImmutableState(substate, ['answers']),
  );
};

const makeSelectValidFunds = () => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    getImmutableState(substate, ['validFunds']),
  );
};

const makeSelectPayment = section => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    getImmutableState(substate, ['payment']),
  );
};

const makeSelectConvertCurrency = () => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    substate.get('convertCurrency'),
  );
};

const makeSelectChangeCurrency = () => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    substate.get('changeCurrency'),
  );
};

//select it by section (only used when section is passed as a prop)
const makeSelectSectionQuestions = section => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    getImmutableState(substate, ['questions', section]),
  );
};

//select it by section (only used when section is passed as a prop)
const makeSelectDoctorsList = () => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    getImmutableState(substate, ['doctorsList']),
  );
};

const makeSelectUWStatus = () => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    substate.get('UWStatus'),
  );
};

const makeSelectConsentStatus = () => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    substate.get('consentStatus'),
  );
};

const makeSelectDocumentStatus = () => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    substate.get('documentStatus'),
  );
};

const makeSelectSummaryLink = () => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    substate.get('summaryLink'),
  );
};

const makeSelectConsentLink = () => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    substate.get('consentLink'),
  );
};
const makeSelectApplicationFormPage = () =>
  createSelector(selectApplicationFormPageDomain, substate => substate.toJS());

const makeSelectUWLink = () => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    substate.get('uwLink'),
  );
};

const makeSelectCountIdvSubmit = () => {
  return createSelector(selectApplicationFormPageDomain, substate =>
    substate.get('countIdvSubmit'),
  );
};

const makeSelectemailSentStatus = () =>
  createSelector(selectApplicationFormPageDomain, substate =>
    substate.get('emailSent'),
  );

const makeSelectEmailErrorMessage = () =>
  createSelector(selectApplicationFormPageDomain, substate =>
    substate.get('emailErrorMessage'),
  );

const makeSelectFundsBelongToNexusOrDever = () =>
  createSelector(selectApplicationFormPageDomain, substate =>
    substate.get('fundsBelongToNexusOrDever'),
  );

export default makeSelectApplicationFormPage;
export {
  makeSelectUWStatus,
  makeSelectDoctorsList,
  makeSelectAnswers,
  selectApplicationFormPageDomain,
  makeSelectSectionAnswers,
  makeSelectSectionQuestions,
  makeSelectQuestions,
  makeSelectPaymentType,
  makeSelectPaymentDetail,
  makeSelectConvertCurrency,
  makeSelectChangeCurrency,
  makeSelectPayment,
  makeSelectValidFunds,
  makeSelectConsentStatus,
  makeSelectDocumentStatus,
  makeSelectSummaryLink,
  makeSelectConsentLink,
  makeSelectUWLink,
  makeSelectCountIdvSubmit,
  makeSelectemailSentStatus,
  makeSelectEmailErrorMessage,
  makeSelectFundsBelongToNexusOrDever,
};
