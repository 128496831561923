/* eslint-disable react/no-unused-prop-types */
/**
 *
 * LoginPage
 *
 */
import LoginLogoSrc from 'images/zurich-logo-landing.svg';
import { Grid, Button, Typography, Link } from '@material-ui/core';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { useTheme } from '@material-ui/core/styles';
import { useOktaAuth } from '@okta/okta-react';
import { makeSelectAuth, makeSelectCsrf } from 'containers/App/selectors';
import { FormattedMessage } from 'react-intl';
import Footnote from 'components/Footnote';
import { getCsrfAction } from 'containers/App/actions';
import saga from './saga';
import reducer from './reducer';
import messages from './messages';
import { loginAction } from './actions';
import { makeSelectLoading, makeSelectError } from './selectors';

export function LoginPage({ login, isLoading, error, auth, getCsrf, csrf }) {
  useInjectReducer({ key: 'loginPage', reducer });
  useInjectSaga({ key: 'loginPage', saga });
  const theme = useTheme();
  const { authState, oktaAuth } = useOktaAuth();

  if (auth?.okta?.accessToken) {
    return <Redirect to="/dashboard" />;
  }

  const triggerOktaLogin = async () => {
    if (authState) {
      return oktaAuth.signInWithRedirect({ pkce: false });
    }
    return false;
  };

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item>
        <img
          src={LoginLogoSrc}
          alt="people"
          style={{
            height: 152,
            marginTop: '16vh',
            marginBottom: theme.spacing(10),
          }}
        />
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          onClick={triggerOktaLogin}
          // onClick={() => {
          // return <Redirect to="/dashboard" />
          // }}
        >
          <FormattedMessage {...messages.login} />
        </Button>
      </Grid>
      <Grid item>
        <Typography
          variant="body2"
          style={{
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(3.5),
          }}
        >
          <FormattedMessage {...messages.registeredQ} />
          <Link to="/register" component={RouterLink}>
            <FormattedMessage {...messages.signUp} />
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Footnote />
      </Grid>
      {/* <LoginForm
        initialValues={{
          username: '',
          password: '',
        }}
        onSubmit={immutableValues => login(immutableValues.toJS())}
      /> */}
    </Grid>
  );
}

LoginPage.propTypes = {
  login: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  auth: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  isLoading: makeSelectLoading(),
  error: makeSelectError(),
  auth: makeSelectAuth(),
  csrf: makeSelectCsrf(),
});

function mapDispatchToProps(dispatch) {
  return {
    login: ({ username, password }) =>
      dispatch(loginAction({ username, password })),
    getCsrf: () => dispatch(getCsrfAction()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LoginPage);
