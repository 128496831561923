import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { map, filter, some } from 'lodash/collection';
import { compose } from 'redux';
import {
  Grid,
  FormControlLabel,
  Typography,
  Button,
  Radio,
  Box,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import messages from 'containers/ApplicationFormPage/messages';
import {
  uploadFileDocumentsAction,
  deleteFileDocumentsAction,
} from 'containers/ApplicationFormPage/actions';

//components
import ActionContainer from './actionContainer';
import FormRadioGroup from 'components/FormRadioGroup';
import { getApplicationDetailsAction } from 'containers/ApplicationPage/actions';
import CustomRadio from 'components/CustomRadioButton';

const useStyle = makeStyles(theme => ({
  lifeInsured: {
    // padding: '3rem',
    maxWidth: '100%',
  },
  actionContainer: {
    margin: '.2rem 1rem',
    border: '1px solid #ccc',
  },
}));

function LifeInsured(props) {
  const theme = useTheme();
  const classes = useStyle();
  // const intl = useIntl();

  const [openSend, setOpenSend] = React.useState(false);

  const {
    uploadFile,
    deleteFile,
    applicationId,
    fields,
    formValues,
    getApplicationDetails,
    parsedApplicationDetails,
    disabledFields,
    ...propList
  } = props;

  const handleOpen = person => {
    // console.log(
    //   `handler handleOpen opened! for ${person.firstName} ${person.lastName}`,
    // );
  };

  const handleSend = person => {
    // console.log(
    //   `handler handleSend opened! for ${person.firstName} ${person.lastName}`,
    // );
  };

  const handleFileUpload = (
    files,
    party,
    applicationId,
    documentRequirement,
  ) => {
    files.filter(item => item instanceof File).length > 0 &&
      uploadFile({
        files: files.filter(item => item instanceof File),
        party,
        applicationId,
        documentRequirement,
      });
  };

  const handleFileDelete = (document, party, applicationId) => {
    deleteFile({ document, party, applicationId });
  };

  //UPLOAD FUNCTIONS

  const handlePhotoFile = e => {
    document.getElementById('selectImage').click();
  };
  const handleCameraFile = e => {
    document.getElementById('captureImage').click();
  };

  return fields.map((field, index) => {
    const party = formValues.parties[index];
    const documentRequirements = party.documentRequirements;

    const requiredDocs = filter(documentRequirements, doc => {
      return doc.isRequired;
    });
    //check all required doc if isUploaded = true
    const requiredDocsUploaded = some(requiredDocs, ['isUploaded', true]);

    //get POA or POI
    const proofDocsRequired = filter(documentRequirements, docReq => {
      return docReq.tag === 'POI' || docReq.tag === 'POA';
    }).sort((a, b) => {
      if (a.tag > b.tag) return -1;
      if (a.tag < b.tag) return 1;
      return 0;
    });

    return (
      <div key={`${field}`} className={classes.lifeInsured}>
        <Grid container spacing={3}>
          <Grid item xs={4} style={{ marginTop: theme.spacing(2) }}>
            {/* <NoticeBox variant="error" mb={4}>
              <Typography variant="body2" color="inherit">
                <FormattedMessage {...messages.paymentErrorDeclined} />
              </Typography>
            </NoticeBox> */}
            <Typography
              variant="h3"
              color="primary"
            >{`${party.firstName} ${party.lastName}`}</Typography>
          </Grid>
          {index === 0 && (
            <Grid align="right" item xs={8}>
              <Button
                variant="outlined"
                style={{ color: theme.palette.primary.dark }}
                onClick={() => {
                  getApplicationDetails(applicationId);
                }}
              >
                <FormattedMessage {...messages.refresh} />
              </Button>
            </Grid>
          )}
          {requiredDocs && requiredDocs.length > 0 && (
            <Grid item xs={12} className={classes.actionContainer}>
              {proofDocsRequired.map((documentRequirement, index2) => {
                return (
                  <Box key={`${documentRequirement.tag}-${index2}`}>
                    <ActionContainer
                      actionTitle={documentRequirement.text}
                      applicationId={applicationId}
                      documentRequirement={{ ...documentRequirement }}
                      party={party}
                      parsedApplicationDetails={parsedApplicationDetails}
                      handlePhotoFile={handlePhotoFile}
                      handleCameraFile={handleCameraFile}
                      handleFileUpload={handleFileUpload}
                      handleFileDelete={handleFileDelete}
                      proofDocsRequired={proofDocsRequired}
                      deleteFile={deleteFile}
                      disabledFields={disabledFields}
                      {...propList}
                    />
                  </Box>
                );
              })}
            </Grid>
          )}
          {filter(documentRequirements, reqDoc => {
            return ['AD', 'UW', 'VISA'].includes(reqDoc.tag);
          }).map((documentRequirement, index2) => {
            return (
              <Grid
                item
                xs={12}
                className={classes.actionContainer}
                key={`${documentRequirement.tag}-${index2}`}
              >
                <ActionContainer
                  actionTitle={documentRequirement.text}
                  handlePhotoFile={handlePhotoFile}
                  applicationId={applicationId}
                  documentRequirement={{ ...documentRequirement }}
                  party={party}
                  parsedApplicationDetails={parsedApplicationDetails}
                  handleFileUpload={handleFileUpload}
                  handleFileDelete={handleFileDelete}
                  deleteFile={deleteFile}
                  disabledFields={disabledFields}
                  {...propList}
                />
              </Grid>
            );
          })}
          {requiredDocsUploaded && (
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="body2" style={{ marginRight: '3rem' }}>
                  {
                    <FormattedMessage
                      {...messages.documentRequireTranslation}
                    />
                  }
                </Typography>

                <Field
                  name={`${field}.documentNeedTranslation`}
                  type="radio"
                  component={FormRadioGroup}
                  normalize={value => value === 'true'}
                  value={
                    party.documentNeedTranslation === null
                      ? false
                      : party.documentNeedTranslation
                  }
                  onBlur={e => {
                    e.preventDefault();
                  }}
                >
                  <FormControlLabel
                    control={<CustomRadio />}
                    value={true}
                    label="Yes"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={<CustomRadio />}
                    value={false}
                    label="No"
                    labelPlacement="end"
                  />
                </Field>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    );
  });
}

LifeInsured.propTypes = {};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    uploadFile: data => dispatch(uploadFileDocumentsAction(data)),
    deleteFile: data => dispatch(deleteFileDocumentsAction(data)),
    getApplicationDetails: data => dispatch(getApplicationDetailsAction(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LifeInsured);
