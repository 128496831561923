import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { api } from 'environments';
import request from 'utils/request';
import { setErrorAction as setAppErrorAction } from 'containers/App/actions';
import globalMessages from 'containers/App/messages';
import { SET_OKTA_TOKEN_ACTION } from './constants';
import { setErrorAction } from './actions';
import { followUpLogin } from 'containers/App/saga';
import { setAppLoadingAction } from 'containers/App/actions';
import { makeSelectCsrf } from 'containers/App/selectors';

export function* getAppToken(action) {
  const {
    oktaAccessToken,
    productCode,
    productVersion,
    agencyId,
  } = action.payload;
  const csrf = yield select(makeSelectCsrf());
  const metadata = JSON.stringify({
    userAgent: navigator.userAgent,
  });
  const endpoint = `${api.host}/api/v${api.version}/b2b/auth/login-sso`;
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${oktaAccessToken}`,
    },
    body: JSON.stringify({
      token: oktaAccessToken,
      metadata,
    }),
  };

  try {
    // yield call(getCsrfToken);
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield call(followUpLogin, {
        agent: response.data.agent,
        okta: { accessToken: oktaAccessToken },
        emailId: response.data.oktaUser.email,
        productCode,
        productVersion,
        agencyId,
        isAdmin: response.data.oktaUser.isAdmin, 
        agenciesAssociated: response.data.agenciesAssociated,
        region: response.data.region
      });
    } else {
      yield put(
        setAppErrorAction({
          message: globalMessages.errSSOUserIsNotAuthorized,
        }),
      );
      yield put(
        setErrorAction({
          message: globalMessages.errSSOUserIsNotAuthorized,
        }),
      );
    }
  } catch (err) {
    yield put(
      setAppErrorAction({
        message: globalMessages.errSSOUserIsNotAuthorized,
      }),
    );
    yield put(
      setErrorAction({
        message: globalMessages.errSSOUserIsNotAuthorized,
      }),
    );
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* getCsrfToken(action) {
  const endpoint = `${api.host}/security/csrfToken`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response._csrf) {
      sessionStorage.setItem('csrf', response._csrf);
    }
  } catch (err) {
    console.log(err);
  }
}

// Individual exports for testing
export default function* oktaAuthCallbackSaga() {
  // See example in containers/HomePage/saga.js
  yield all([takeLatest(SET_OKTA_TOKEN_ACTION, getAppToken)]);
}
