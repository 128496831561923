/**
 *
 * QuoteForm
 *
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Collapse,
  Button,
  IconButton,
  Card,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import { fromJS } from 'immutable';
import { InjectedFormProps } from 'redux-form';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import { Cancel, CheckCircle, Create } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import FormSlider from 'components/FormSlider';
import { formatMonetaryValue } from 'helpers/math';
import FormSelect from 'components/FormSelect';
import { isEmpty } from 'lodash';
import IconPencilBlue from 'images/pencil-light-blue.svg';
import FormPremium from 'components/FormPremium';
import FormNumberField from 'components/FormNumberField';
import FormTextField from 'components/FormTextField';
import globalMessages from 'containers/App/messages';
import {
  setGrowthRateAction,
  setSustainabilityYearsAction,
  setPolicyTermAction,
  resetProductConfigsAction,
  setManualPremiumAction,
} from 'containers/InforceIllutrationPage/actions';
import Benefits from './Benefits';
import messages from '../../messages';
import validate from './validate';
import { lifeText } from '../../constants';
import { productCodeCheck } from 'helpers/constants';
import {
  makeSelectPolicyConfig,
  makeSelectInitProductConfig,
} from '../../selectors';
import {
  checkNextMonthWithInOneMonth,
  getAnniversaryDate,
  getAgeOfLife,
  getChosenInsured,
  calculateSustainYears,
  getPremiumRange,
  getFrequencyLabel,
  checkCanEditPremium,
  renderTerm,
  checkValidTerm,
  getMinValidTerm,
  getFrequencyOptions,
  getInitPremiumByFrequency,
  getFrequencyOptionsForFuturaInforce,
  getPremiumOfNewFrequency,
  isPrmIndexationApplicable,
  getRestartFromOptions,
  isPaidUpPolicyWithPremiumIncreaseAfterWOLCalc
} from '../../helper';
import moment from 'moment';
import { async } from 'regenerator-runtime';
import FormBooleanOption from 'components/FormBooleanOption';
 import CustomRadio from 'components/CustomRadioButton';

const HeaderContent = styled(({ theme, ...props }) => <div {...props} />)`
  && {
    padding: 40px 0;
    background-color: ${props => props.theme.palette.primary.dark};
    color: ${props => props.theme.palette.common.white};
    position: relative;
  }
`;

const BottomRow = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60px;
    padding: ${props => props.theme.spacing(1)}px;
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    ${props =>
      props.isLock &&
      `
    background-color: #F2F2F2;
  `}
  }
`;

const VerticalLine = styled.hr`
  width: 1px;
  height: 100%;
  position: absolute;
  left: 50%;
  margin: 0;
  border: 0;
  top: 0;
  transform: translateX(-50%);
  background-color: ${props => props.color};
`;
const VerticalLine33 = styled.hr`
  width: 1px;
  height: 100%;
  position: absolute;
  left: 33.33%;
  margin: 0;
  border: 0;
  top: 0;
  transform: translateX(-50%);
  background-color: ${props => props.color};
`;
const VerticalLine66 = styled.hr`
  width: 1px;
  height: 100%;
  position: absolute;
  left: 66.66%;
  margin: 0;
  border: 0;
  top: 0;
  transform: translateX(-50%);
  background-color: ${props => props.color};
`;

function QuoteForm({
  premium,
  options,
  policyConfig,
  formValues,
  currency,
  startDate,
  productConfig,
  term,
  growthRate,
  paymentFrequency,
  restartFrom,
  coverages,
  singlePremium,
  lifeAssured,
  isValidTerm,
  isEditPremium,
  handleSubmit,
  onSubmit,
  change,
  agentDetails,
  maxTerm,
  minTerm,
  errorCoverages,
  initProductConfig,
  sustainabilityYears,
  resetSustainabilityYears,
  setPolicyTerm,
  resetProductConfigs,
  setNextButtonStatus,
  setManualPremium,
  partialWithdrawal,
  harvestPolicyDetails,
  setPremiumSkip
}) {
  const theme = useTheme();
  let MIN_GROWTH_RATE = policyConfig?.features?.growthRate?.ifi?.minRate;
  let MAX_GROWTH_RATE = policyConfig?.features?.growthRate?.ifi?.maxRate;
  const selectedAgency = sessionStorage.getItem('agencySelected');
  if(productConfig?.productCode && selectedAgency && selectedAgency === '8910138') {
    switch (productConfig.productCode) {
      case 'SCFUT' :
        MIN_GROWTH_RATE = 6;
        MAX_GROWTH_RATE = 6;
        break;
      case 'SAVP3' :
        MIN_GROWTH_RATE = 7;
        MAX_GROWTH_RATE = 7;
        break;
      case 'WAPL1' :
        MIN_GROWTH_RATE = 7;
        MAX_GROWTH_RATE = 7;
        break;
    }
  }
  const [open, setOpen] = useState(false);
  const [sspTwoOpen, setSspTwoOpen] = useState(false);
  const [sspThreeOpen, setSspThreeOpen] = useState(false);
  const [sspTwoZeroOpen, setSspTwoZeroOpen] = useState(false);
  const [sspThreeZeroOpen, setSspThreeZeroOpen] = useState(false);
  const [isPartialWithdrawal,setIsPartialWithdrawal] = useState(false);  
  const [isInverseTransactionOfPartialWithdrawal,setIsInverseTransactionOfPartialWithdrawal] = useState(false) // Track the set of transaction which can not be performed with partial withdrawal;
  const isLockQuoteForm = false;
  const [isMerged, setIsMerged] = useState(false);
  const [isASPWOL, setIsASPWOL] = useState(true);
  const [isASPManual, setASPManual] = useState(true); 
  const [isResetForm, setResetForm] = useState(false);
  const [isPolicyWholeOfLife, setPolicyWholeOfLife] = useState(false);
  const [isChooseToPayAllDues, setIsChooseToPayAllDues] = useState(true);
  const [isRestartMonthInFrequency,  setRestartMonthInFrequency] =  useState(false);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [lastPremium, setLastPremium] = useState(premium);
  const [editData, setEditData] = useState({ editIndex: 0, editInsured: 0 });
  const [editType, setEditType] = useState(0);
  const [firstMissedDue, setFirstMissedDue]= useState('');
  const [initialAge] = useState(initProductConfig.singlePremiumTerm === 3 ? sustainabilityYears : null);
  const [restartFromOptions, setRestartFromOptions] = useState([]);
  const isSingePaymentFrequency = productConfig.paymentFrequency === 'Single';
  const isFutu5 = productConfig.productCode === 'FUTU5';
  const isPolicyPaidUp = harvestPolicyDetails?.policyStatus === "C" ? true : false;
  const isInNAPRAPPeriod = productConfig.isInNAPRAPPeriod;
  const agencyNumber = agentDetails?.agentNumber;
  policyConfig.agencyNumber = (agencyNumber);
  const isPrmIndexation = formValues.premiumIndexation;
  const isPrmIndexApplicable = isPrmIndexationApplicable(productConfig.productCode);
  //New Changes done as part of CR - Salman
  const isNBSliceInNAPRAPPeriod = productConfig.isNBSliceInNAPRAPPeriod;
  const reinstatementCount = harvestPolicyDetails?.reinstateCount;
  //Changes as part of IIu-232
  const [newPremium,setNewPremium] = useState(premium);
  if (productConfig.additionalSinglePremium != 0){
    productConfig.mergeASP = productConfig.additionalSinglePremium;
    change('mergeASP', productConfig.additionalSinglePremium);
  }
  let defaultSet = false;
    

  // Editing premium and calculate premium should be disabled if the premium holiday is active for that in-force policy
  // Editing premium should be disabled if advance premium payment done
  const canEditPremium = (productConfig.isPremiumHoliday || productConfig.isAdvancePremiumPaid) ? false : 
 (productConfig.singlePremiumTerm >= 0) && isASPWOL && (productConfig.regularPremiumSliceLength === 0)
  ? true
  : isValidTerm && checkCanEditPremium(productConfig, initProductConfig)

  const canDecreasePremium =
  (productConfig.singlePremiumTerm >= 0) && (productConfig.regularPremiumSliceLength === 0)
  ? true
  : isValidTerm && checkCanEditPremium(productConfig, initProductConfig)

  const termDisplay = renderTerm(term);

  const handleChange = event => {

    setNextButtonStatus(false);
    let chosenRestartFromDate = event.target.value;
    setRestartFromOptions([{
      title: chosenRestartFromDate,
      value: chosenRestartFromDate,
      disabled: false,
    }])


    // Only for lapsed/paid-up policies, allow to change frequency when “Policy restart “ month matches the month in the Frequency list. - IIU 140

    if (formValues.policyStatus == 'L' || formValues.policyStatus == 'C') {
    getPaymentFrequencyOptions();
    let restartMonth = "";
    setRestartMonthInFrequency(false);
    if (chosenRestartFromDate) {
      restartMonth = chosenRestartFromDate.split(" ", 1)[0]; // extract month from restart from option
    }
    for (let i = 0; i < frequencyOptions.length; i++) {
      if (frequencyOptions[i].title.indexOf(restartMonth) !== -1 && frequencyOptions[i].disabled == false) { // If restart month is in frequency change options, then the edit frequency option should be enabled
        setRestartMonthInFrequency(true);
      } 
    }
    }
    
    if(singlePremium != 0) {productConfig.oldSinglePremium = singlePremium;}
    if(productConfig.additionalSinglePremium != 0){productConfig.oldAdditionalSinglePremium = productConfig.additionalSinglePremium;}
    if (chosenRestartFromDate  === firstMissedDue){
      // enable ASP only if the advisor choses to pay from first missed date
      setIsChooseToPayAllDues(true);
      setNextButtonStatus(true);
      change('singlePremium', productConfig.oldSinglePremium);
      change('additionalSinglePremium', productConfig.oldAdditionalSinglePremium);
      productConfig.additionalSinglePremium = productConfig.oldAdditionalSinglePremium;      
    } else {
      setIsChooseToPayAllDues(false);
      setPremiumSkip(true)
      change('singlePremium', 0);
      change('additionalSinglePremium', 0);
      productConfig.additionalSinglePremium = 0;
    }
 
  };

  const isValidAnniversaryDate = checkNextMonthWithInOneMonth(
    getAnniversaryDate(startDate),
  );

  const chosenInsured = getChosenInsured(lifeAssured);
  const ageOfLife = getAgeOfLife(
    lifeAssured.lifeBasis,
    lifeAssured,
    chosenInsured,
    startDate,
  );

  let isCoveragesError = false;
  if (!isEmpty(errorCoverages)) {
    errorCoverages?.coverages &&
      errorCoverages?.coverages.map(warningBenefit => {
        if (!isEmpty(warningBenefit)) {
          isCoveragesError = true;
        }
      });
  }

  const refreshEditQuote = () => {
    setResetForm(true);
    setNextButtonStatus(false);
    resetProductConfigs(initProductConfig);
    if (initProductConfig.singlePremiumTerm === 3 ){
       resetSustainabilityYears(initialAge);
     } else {
      resetSustainabilityYears(null);
    }
    setManualPremium(false);
    setIsMerged(false);
    setSspTwoOpen(false);
    setSspThreeOpen(false);
    setSspTwoZeroOpen(false);
    setSspThreeZeroOpen(false);
    setIsInverseTransactionOfPartialWithdrawal(false);
    setIsPartialWithdrawal(false);
    setASPManual(true);
    setIsASPWOL(true);

    setFrequencyOptions(
      productConfig.name === 'Futura'?
      getFrequencyOptionsForFuturaInforce(true,
        initProductConfig.premium,
        policyConfig?.features?.currencyPremiumSize,
        currency,
        getAnniversaryDate(startDate),
        paymentFrequency
        ):
      getFrequencyOptions(
        true,
        initProductConfig.premium,
        policyConfig?.features?.currencyPremiumSize,
        currency,
      ),
    );
    setIsPartialWithdrawal();
    change('isEditTerm', false);
    change('isEditPremium', false);
    change('term', initProductConfig.term);
    change('premium', initProductConfig.premium);
    change('coverages', fromJS(initProductConfig.coverages));
    change('paymentFrequency', initProductConfig.paymentFrequency);
    change('growthRate', initProductConfig.growthRate);
    change('singlePremium', initProductConfig.singlePremium);
    change('singlePremiumTerm', initProductConfig.singlePremiumTerm);
    change('policyCoverTerm', initProductConfig.policyCoverTerm);
    change('partialWithdrawal',null);
    change('isMerged', false);
    change('mergeSustainability', false);
    change('policyWOL', initProductConfig.policyWOL);
    change('restartFrom', firstMissedDue);
    productConfig.isMerged = false;
    productConfig.mergeSustainability = false;
    setFrequencyOptions(
      productCodeCheck('FUTU', productConfig.productCode)?
      getFrequencyOptionsForFuturaInforce(true,
        initProductConfig.premium,
        policyConfig?.features?.currencyPremiumSize,
        currency,
        getAnniversaryDate(startDate),
        initProductConfig.paymentFrequency,
        initProductConfig.paymentFrequency
        ):
      getFrequencyOptions(
        true,
        initProductConfig.premium,
        policyConfig?.features?.currencyPremiumSize,
        currency,
      ),
    );
    
  };
useEffect(() => {
   if ((productConfig.policyStatus == 'L' || productConfig.policyStatus == 'C')) {
    setRestartFromOptions(getRestartFromOptions(productConfig.premiumDueData, isNBSliceInNAPRAPPeriod, isInNAPRAPPeriod, reinstatementCount));
  }
 
  if (productConfig && productConfig.premiumDueData 
    && (productConfig.policyStatus == 'L' || productConfig.policyStatus == 'C')
    && defaultSet == false) {
     // to populate payment frequency options for paid up / lapsed policies
     setFrequencyOptions(
      productCodeCheck('FUTU', productConfig.productCode)?
      getFrequencyOptionsForFuturaInforce(true,
        initProductConfig.premium,
        policyConfig?.features?.currencyPremiumSize,
        currency,
        getAnniversaryDate(startDate),
        initProductConfig.paymentFrequency,
        initProductConfig.paymentFrequency
        ):
      getFrequencyOptions(
        true,
        initProductConfig.premium,
        policyConfig?.features?.currencyPremiumSize,
        currency,
      ),
    );

    // check whether restart from month is available in frequency options

    if (formValues.restartFrom != '')
    {
    let restartMonth = "";
    setRestartMonthInFrequency(false);
    if (formValues.restartFrom) {
      restartMonth = formValues.restartFrom.split(" ", 1)[0]; // extract month from restart from option
    }

    for (let i = 0; i < frequencyOptions.length; i++) {
      if (frequencyOptions[i].title.indexOf(restartMonth) !== -1 && frequencyOptions[i].disabled == false) { // If restart month is in frequency change options, then the edit frequency option should be enabled
        setRestartMonthInFrequency(true);
      } 
    }
    }
  }

  // condition to check whether Policy age is sustaining whole of life
  if (formValues.isAgeWOL) {
    setPolicyWholeOfLife(true)
  } else {
    setPolicyWholeOfLife(false)
  }

}, [formValues]);


useEffect(() => {
  if (productConfig && productConfig.premiumDueData 
    && (productConfig.policyStatus == 'L' || productConfig.policyStatus == 'C')
    && defaultSet == false) {

    let premiumDueDateFirst = moment(productConfig.premiumDueData[0].passDueDate).toDate();
    let premiumDueMonth = moment(premiumDueDateFirst).format('MMMM');
    let premiumDueYear = moment(premiumDueDateFirst).year();

    let restartFromValue = premiumDueMonth + ' ' + premiumDueYear;
    setFirstMissedDue(restartFromValue);
    defaultSet = true;
    change('restartFrom', restartFromValue); 

  }
    
}, []) 

  useEffect(() => {
    setPolicyTerm(sustainabilityYears);
  }, [sustainabilityYears]);

  useEffect(() => {
    if (premium !== initProductConfig.premium) {
      setFrequencyOptions(
        productCodeCheck('FUTU', productConfig.productCode)
        ?
        getFrequencyOptionsForFuturaInforce(true,
          premium,
          policyConfig?.features?.currencyPremiumSize,
          currency,
          getAnniversaryDate(startDate),
          paymentFrequency,
          initProductConfig.paymentFrequency
          ):
        getFrequencyOptions(),
      );
    } else {
      setFrequencyOptions(
        productCodeCheck('FUTU', productConfig.productCode)
        ?
        getFrequencyOptionsForFuturaInforce(true,
          premium,
          policyConfig?.features?.currencyPremiumSize,
          currency,
          getAnniversaryDate(startDate),
          paymentFrequency,
          initProductConfig.paymentFrequency
          ):
        getFrequencyOptions(
          true,
          premium,
          policyConfig?.features?.currencyPremiumSize,
          currency,
        ),
      );
    }
  }, [currency]);
  const premiumRange = getPremiumRange(
    policyConfig,
    paymentFrequency,
    currency,
  );

  // function to load payment frequency option and to set the initial value that is set during page load
  function getPaymentFrequencyOptions () {
        change('paymentFrequency', initProductConfig.paymentFrequency);
        setFrequencyOptions(
          productCodeCheck('FUTU', productConfig.productCode)?
          getFrequencyOptionsForFuturaInforce(true,
            initProductConfig.premium,
            policyConfig?.features?.currencyPremiumSize,
            currency,
            getAnniversaryDate(startDate),
            initProductConfig.paymentFrequency,
            initProductConfig
            ):
          getFrequencyOptions(
            true,
            initProductConfig.premium,
            policyConfig?.features?.currencyPremiumSize,
            currency,
          ),
        );
  }

  function checkSinglePremium(){
    if (productConfig.singlePremiumTerm > 0){
      return true
    } else {
      return false
    }
  }

  function isSmokerChanged() {
    let isSmokerChanged = false;
    let inforceL1SmokerStatus = lifeAssured?.insuredOne?.isSmoke ? "Y" : "N";
    let inforceL2SmokerStatus = lifeAssured?.insuredTwo?.isSmoke ? "Y" : "N";

    harvestPolicyDetails?.clients.map(val => {
      if (val.policyClientSequence === "1") {
        if (val.smokerStatus != inforceL1SmokerStatus){
          isSmokerChanged = true;
        }
      }
      if (val.policyClientSequence === "2") {
        if (val.smokerStatus != inforceL2SmokerStatus){
          isSmokerChanged = true;
        }
      }
    });
    return isSmokerChanged;
  }

  function isLifeInsuredChanged() {
    console.log('isLifeInsuredChanged -> harvestPolicyDetails', harvestPolicyDetails);
    console.log('isLifeInsuredChanged -> lifeAssured', lifeAssured);
    let inforceLifeBasis = harvestPolicyDetails?.policyBasisType;
    return lifeAssured?.lifeBasis !== inforceLifeBasis;
  }
  function isPolicySatisfyMinPartialWithdrawal() {
    let minPartialWithdrawal = 1000; //USD
    policyConfig?.features?.partialWithdrawal.map(val => {
      if (currency === val.currency) {
        minPartialWithdrawal = val.min;
        return;
      }
    });
    if(harvestPolicyDetails?.maxPartialSurrender >=minPartialWithdrawal){
      return true;
    } else {
      return false;
    }
  }
  
  function checkAge(){
    const age  = calculateSustainYears(sustainabilityYears,ageOfLife,initialAge);
    if(age === 'Whole of life'){
      productConfig.policyTerm = 'WholeOfLife'
      change('policyTerm','WholeOfLife')
      change('isAgeWOL', true);
    } else {
      change('isAgeWOL', false);
    }
    return age;
  }
  if (productConfig.name === 'Futura'){
      productConfig.existingFrequency = initProductConfig.paymentFrequency;
  }
  
  useEffect(() => {
    console.log("useeffect frequency options =>", frequencyOptions)
  },[frequencyOptions]);
  const frequencyLabel = getFrequencyLabel(paymentFrequency);

  function isReinstatementWithPaidLapsePolicy() {
    console.log('inside isReinstatementWithPaidLapsePolicy' ,productConfig)
    return productConfig.isReinstate && (productConfig.policyStatus == 'L')
  }

  //If Whole of Life calculation is done and there is increase in premium and NB and Increment slice is NAP/RAP period and policyStatus is paidUp then disable save quote
  if(isPaidUpPolicyWithPremiumIncreaseAfterWOLCalc(productConfig,isChooseToPayAllDues)) {
    setNextButtonStatus(false);
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <HeaderContent container theme={theme}>
            <Grid container style={{ paddingLeft: '20px' }}>
            <Grid item xs={!checkSinglePremium() && !(productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0) ?4:6} spacing={1}>
                <Grid item style={{ paddingBottom: '5px' }}>
                  <Typography component="span" variant="h3">
                  {checkSinglePremium()
                  ? <FormattedMessage {...messages.premiumPaid} />
                  : <FormattedMessage {...messages.premium} />}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingBottom: '10px' }}></Grid>

                {checkSinglePremium()
                ? <Grid item>
                    <span
                      id="primaryPremiumAmount"
                      style={{
                        color: 'inherit',
                        fontSize: '2.6rem',
                        lineHeight: 1,
                        fontWeight: 700,
                      }}
                    >
                      {currency} {formatMonetaryValue(productConfig.sspSliceOne, true)}{' '}
                    </span>
                    <Typography
                      color="inherit"
                      variant="body1"
                      component="span"
                      style={{ color: '#9B9A9B' }}
                    >
                      1st Year
                    </Typography>
                    <Grid item style={{ paddingBottom: '10px' }}></Grid>
                  </Grid>
                  : productConfig.regularPremiumSliceLength === 0
                    ? <Grid item>
                        <span
                          id="primaryPremiumAmount"
                          style={{
                            color: 'inherit',
                            fontSize: '2.6rem',
                            lineHeight: 1,
                            fontWeight: 700,
                          }}
                        >
                          {currency} {formatMonetaryValue(premium, true)}{' '}
                        </span>
                        <Typography
                          color="inherit"
                          variant="body1"
                          component="span"
                          style={{ color: '#9B9A9B', display: "block"  }}
                          
                        >
                          {frequencyLabel}
                        </Typography>
                        <Grid item style={{ paddingBottom: '15px' }}></Grid>
                        { productConfig.additionalSinglePremium != 0
                      ? <Grid>
                          <Grid item style={{ paddingBottom: '5px' }}>
                            <Typography component="span" variant="h3">
                            <FormattedMessage {...messages.ASP} />
                            </Typography>
                          </Grid>
                          <Grid item style={{ paddingBottom: '10px' }}></Grid>  
                          <Grid item>
                            <span
                              id="primaryPremiumAmount"
                              style={{
                                color: 'inherit',
                                fontSize: '2.6rem',
                                lineHeight: 1,
                                fontWeight: 700,
                              }}
                            > 
                              {currency} {formatMonetaryValue(productConfig.additionalSinglePremium, true)} {' '}
                            </span>
                          </Grid>
                        </Grid>
                      : productConfig.additionalSinglePremium === 'Sufficient Premium'
                      ? <Grid>
                      <Grid item style={{ paddingBottom: '5px' }}>
                        <Typography component="span" variant="h3">
                        <FormattedMessage {...messages.ASP} />
                        </Typography>
                      </Grid>
                      <Grid item style={{ paddingBottom: '10px' }}></Grid>  
                      <Grid item>
                        <span
                          id="primaryPremiumAmount"
                          style={{
                            color: 'inherit',
                            fontSize: '2.6rem',
                            lineHeight: 1,
                            fontWeight: 700,
                          }}
                        > 
                          {currency} {formatMonetaryValue(0, true)} {' '}
                        </span>
                      </Grid>
                    </Grid>
                      : <Grid></Grid>
                      }
                      </Grid>
                    : <Grid item>
                        <span
                          id="primaryPremiumAmount"
                          style={{
                            color: 'inherit',
                            fontSize: '2.6rem',
                            lineHeight: 1,
                            fontWeight: 700,
                          }}
                        >
                          {currency} {formatMonetaryValue(premium, true)}{' '}
                        </span>
                        <Typography
                          color="inherit"
                          variant="body1"
                          component="span"
                          style={{ color: '#9B9A9B', display: "block" }}
                        >
                          {frequencyLabel}
                        </Typography>

                        <IconButton
                          disabled={!canEditPremium  || isPartialWithdrawal || !productConfig.isRestart}
                          id="edit"
                          onClick={() => canEditPremium && setOpen(!open)}
                        >
                          <Create
                            style={{
                              color: canEditPremium && !isPartialWithdrawal && productConfig.isRestart
                                ? theme.palette.success.main
                                : theme.palette.grey[400],
                            }}
                          />
                        </IconButton>
                      </Grid>                 
                }

                {productConfig.sspSliceTwo !== 0
                ? <Grid item>
                      <span
                        id="primaryPremiumAmount"
                        style={{
                          color: 'inherit',
                          fontSize: '2.6rem',
                          lineHeight: 1,
                          fontWeight: 700,
                        }}
                      >
                        {currency} {formatMonetaryValue(productConfig.sspSliceTwo, true)}{' '}
                      </span>
                      <Typography
                        color="inherit"
                        variant="body1"
                        component="span"
                        style={{ color: '#9B9A9B' }}
                      >
                        2nd Year
                      </Typography>
                    <Grid item style={{ paddingBottom: '10px' }}></Grid>
                  </Grid>
                : <Grid></Grid>
                }

                {productConfig.sspSliceThree !== 0
                ? <Grid item>
                      <span
                        id="primaryPremiumAmount"
                        style={{
                          color: 'inherit',
                          fontSize: '2.6rem',
                          lineHeight: 1,
                          fontWeight: 700,
                        }}
                      >
                        {currency} {formatMonetaryValue(productConfig.sspSliceThree, true)}{' '}
                      </span>
                      <Typography
                        color="inherit"
                        variant="body1"
                        component="span"
                        style={{ color: '#9B9A9B' }}
                      >
                        3rd Year
                      </Typography>
                    <Grid item style={{ paddingBottom: '10px' }}></Grid>
                  </Grid>
                : <Grid></Grid>
                }
              </Grid>
              {!checkSinglePremium() && !(productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0)?<>
                <VerticalLine33 color="#4066B" />
              <Grid item xs={4} spacing={1}>
                <Grid item style={{ paddingBottom: '5px' }}>
                  <Typography component="span" variant="h3">
                    <FormattedMessage {...messages.ASPShort} />
                  </Typography>
                </Grid>
                <Grid item style={{ paddingBottom: '10px' }}></Grid>
                <Grid item>
                  <span
                    id="primaryPremiumAmount"
                    style={{
                      color: 'inherit',
                      fontSize: '2.6rem',
                      lineHeight: 1,
                      fontWeight: 700,
                    }}
                  >
                    {currency} {formatMonetaryValue(productConfig.additionalSinglePremium, true)} {' '}
                  </span>
                </Grid>
              </Grid>
              <VerticalLine66 color="#4066B" />
              </>: <><VerticalLine color="#4066B" /> </>}
              
              <Grid item xs={!checkSinglePremium() && !(productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0)?4:6} spacing={1}>
                <Grid item style={{ paddingBottom: '5px' }}>
                  <Typography component="span" variant="h3">
                    <FormattedMessage {...globalMessages.age} />
                  </Typography>
                </Grid>
                <Grid item>
                  <span
                    id="primaryPremiumAmount"
                    style={{
                      color: 'inherit',
                      fontSize: '2.6rem',
                      lineHeight: 1,
                      fontWeight: 700,
                    }}
                  >
                    {
                      `${checkAge()} `}
                  </span>
                  <Typography
                    color="inherit"
                    variant="body1"
                    component="span"
                    style={{ color: '#9B9A9B' }}
                  >
                    {lifeAssured.lifeBasis && (
                      <FormattedMessage
                        {...messages[lifeText[lifeAssured.lifeBasis]]}
                      />
                    )}
                  </Typography>
                  <RefreshIcon
                    style={{
                      color: theme.palette.success.light,
                      cursor: 'pointer',
                    }}
                    onClick={() => refreshEditQuote()}
                  ></RefreshIcon>
                </Grid>
              </Grid>

              {checkSinglePremium()
              ? <Grid item xs={6} spacing={1}>
                  <Grid item style={{ paddingBottom: '15px' }}></Grid>
                    <Grid item style={{ paddingBottom: '5px' }}>
                      <Typography component="span" variant="h3" style={{paddingRight: '50px'}}>
                        <FormattedMessage {...messages.premiumDue} />
                      </Typography>
                      {
                        initProductConfig.singlePremiumTerm === 3 && productConfig.sspSliceThree === 0 && productConfig.sspSliceTwo === 0
                        ? isMerged
                          ? <Button
                              style={{
                                backgroundColor: '#4066B3',
                                color: 'white',
                                padding: 6,
                                borderRadius: 10,
                              }}
                              variant="contained"
                              onClick={async () => {
                                if (initProductConfig.singlePremiumTerm === 3 ){
                                  setNextButtonStatus(false);
                                  setIsMerged(false);
                                  setSspTwoZeroOpen(false);
                                  setSspThreeZeroOpen(false);
                                  resetSustainabilityYears(null);
                                  resetProductConfigs(initProductConfig);
                                  productConfig.isMerged = false;
                                  productConfig.singlePremiumTerm = 3;
                                  productConfig.mergeSustainability = false;
                                  await change('singlePremium', initProductConfig.singlePremium);
                                  await change('premium', initProductConfig.premium);
                                  await change('singlePremiumTerm', initProductConfig.singlePremiumTerm);
                                  await change('isGetPremium', false);
                                  await change('isCalculateSustainability', false);
                                  await change('isMerged', false);
                                  await change('policyCoverTerm', initProductConfig.policyCoverTerm);
                                  await change('mergeSustainability', false);
                                  await change('policyWOL', initProductConfig.policyWOL);
                                }                          
                              }}
                            >
                              <FormattedMessage {...messages.unMergeButton} />
                            </Button>
                          : <Button
                              style={{
                                backgroundColor: '#4066B3',
                                color: 'white',
                                padding: 6,
                                borderRadius: 10,
                              }}
                              variant="contained"
                              onClick={async () => {
                                if (productConfig.singlePremiumTerm === 3 ){
                                  productConfig.mergeSustainability = false;
                                  setIsMerged(true);
                                  setSspTwoOpen(false);
                                  if (!isCoveragesError) {
                                    setEditData({ editIndex: 0, editInsured: 0 });
                                    setEditType(0);
                                  }
                                  await change('singlePremium', 0);
                                  await change('isCalculateSustainability', false);
                                  await change('isMerged', true);
                                  await change('isGetPremium', true);
                                  await change('singlePremiumTerm', 2);
                                  await change('mergeSustainability', false);
                                  const policyAge = checkAge()
                                  if (policyAge === 'Whole of life' && initProductConfig.singlePremiumTerm === 3) {
                                    await change('policyWOL', true);
                                  }
                                  handleSubmit();
                                }                          
                              }}
                            >
                              <FormattedMessage {...messages.mergeButton} />
                            </Button>
                        : <Grid></Grid>
                      }
                    </Grid>
                    {
                      isMerged
                      ? <Grid item style={{ paddingBottom: '10px' }}></Grid>
                      : <Grid item style={{ paddingBottom: '5px' }}></Grid>
                    }
                    {productConfig.sspSliceTwo === 0
                    ? isMerged
                      ? <Grid item>
                          <span
                            id="primaryPremiumAmount"
                            style={{
                              color: 'inherit',
                              fontSize: '2.6rem',
                              lineHeight: 1,
                              fontWeight: 700,
                            }}
                          > 
                            {currency} {formatMonetaryValue('0', true)}{' '}
                          </span>
                        </Grid>
                      : <Grid item>
                          <span
                            id="primaryPremiumAmount"
                            style={{
                              color: 'inherit',
                              fontSize: '2.6rem',
                              lineHeight: 1,
                              fontWeight: 700,
                            }}
                          > 
                            {currency} {formatMonetaryValue(premium > productConfig.exisitingPremium ? productConfig.exisitingPremium : premium, true)}{' '}
                          </span>
                          <Typography
                            color="inherit"
                            variant="body1"
                            component="span"
                            style={{ color: '#9B9A9B' }}
                          >
                            {'2nd Year'}
                          </Typography>
                          
                          <IconButton
                              id="edit"
                              disabled={!canDecreasePremium}
                              onClick={() => canDecreasePremium && setSspTwoOpen(!sspTwoOpen)}
                          >
                            <Create
                              style={{
                                color: canDecreasePremium
                                  ? theme.palette.success.main
                                  : theme.palette.grey[400],
                              }}
                            />
                          </IconButton>
                          
                          {/* ########## START  :: EDIT PREMIUM ########## */}
                          <Collapse in={sspTwoOpen}>
                            <Box pt={1}>
                              <Grid container direction="row" spacing={2} alignItems="center">
                                <Grid item xs>
                                  <Field
                                    id= 'premium'
                                    name= 'premium'
                                    component={FormPremium}
                                    min={checkSinglePremium() ? initProductConfig.premium >= premiumRange.min ? 0 : (premiumRange.min - initProductConfig.premium)/(productConfig.singlePremiumTerm - 1) : premiumRange.min}
                                    max={checkSinglePremium() ? productConfig.premium : premiumRange.max}
                                    label=""
                                    diff={100}
                                    isPrimary
                                    type="text"
                                    singleSplitPremium={checkSinglePremium()}
                                    onBlur={() => {
                                      setNextButtonStatus(false);
                                      setManualPremium(true);
                                    }}
                                    onButtonActionClick={() => {
                                      setNextButtonStatus(false);
                                      setManualPremium(true);
                                    }}
                                    onChange={(e, newVal, previousValue) => {
                                      if (
                                        !isResetForm &&
                                        Number(newVal) !== Number(previousValue)
                                      ) {
                                        setNewPremium(Number(newVal))
                                        if (Number(newVal) === 0){
                                          setSspTwoZeroOpen(true);
                                        } else {
                                          setSspTwoZeroOpen(false);
                                        }
                                        change('isEditPremium', true);
                                        setFrequencyOptions(
                                          productCodeCheck('FUTU', productConfig.productCode)
                                          ?
                                          getFrequencyOptionsForFuturaInforce(true,
                                            Number(newVal),
                                            policyConfig?.features?.currencyPremiumSize,
                                            currency,
                                            getAnniversaryDate(startDate),
                                            paymentFrequency,
                                            initProductConfig.paymentFrequency
                                            ):
                                          getFrequencyOptions(
                                            true,
                                            Number(newVal),
                                            policyConfig?.features?.currencyPremiumSize,
                                            currency,
                                          ),
                                        );
                                      } else {
                                        setResetForm(false);
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Collapse>
                          {/* ########## END    :: EDIT PREMIUM ########## */}
                          <Collapse in={sspTwoZeroOpen}>
                            <Box pt={0}>
                              <Grid container direction="row" spacing={1} alignItems="center">
                                <Grid item xs>
                                <Typography
                                style={{ fontSize: '1.18rem',color: theme.palette.error.main, paddingLeft: '58px' }}
                                >
                                <FormattedMessage
                                {...globalMessages.minLimit}
                                values={{ value: formatMonetaryValue(initProductConfig.premium >= premiumRange.min ? 0 : (premiumRange.min - initProductConfig.premium)/(productConfig.singlePremiumTerm - 1),true)}
                                }
                                />
                                </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Collapse>
                        </Grid>
                    : productConfig.sspSliceThree === 0
                      ? <Grid></Grid>
                      : <Grid>
                        <span
                          id="primaryPremiumAmount"
                          style={{
                            color: 'inherit',
                            fontSize: '2.6rem',
                            lineHeight: 1,
                            fontWeight: 700,
                          }}
                        > 
                          {currency} {formatMonetaryValue('0', true)}{' '}
                        </span>
                        </Grid>}

                    <Grid item style={{ paddingBottom: '10px' }}>
                      </Grid>
                      {
                        isMerged || initProductConfig.singlePremiumTerm === 2 || productConfig.sspSliceThree != 0
                        ? <Grid item style={{ paddingBottom: '10px' }}></Grid>
                        : <Grid item>
                        <span
                          id="primaryPremiumAmount"
                          style={{
                            color: 'inherit',
                            fontSize: '2.6rem',
                            lineHeight: 1,
                            fontWeight: 700,
                          }}
                        > 
                          {currency} {formatMonetaryValue(premium > productConfig.exisitingPremium ? productConfig.exisitingPremium : premium, true)}{' '}
                        </span>
                          <Typography
                          color="inherit"
                          variant="body1"
                          component="span"
                          style={{ color: '#9B9A9B' }}
                        >
                          {'3rd Year'}
                        </Typography>
                        {
                          productConfig.sspSliceTwo === 0
                          ? <Grid></Grid>
                          : <IconButton
                          id="edit"
                          disabled={!canDecreasePremium}
                          onClick={() => canDecreasePremium && setSspThreeOpen(!sspThreeOpen)}
                        >
                          <Create
                            style={{
                              color: canDecreasePremium
                                ? theme.palette.success.main
                                : theme.palette.grey[400],
                            }}
                          />
                        </IconButton>
                        }
                        {/* ########## START  :: EDIT PREMIUM ########## */}
                          <Collapse in={sspThreeOpen}>
                            <Box pt={1}>
                              <Grid container direction="row" spacing={2} alignItems="center">
                                <Grid item xs>
                                  <Field
                                    id= 'premium'//{premium < productConfig.exisitingPremium ? "premium" : "singlePremium"}
                                    name= 'premium'//{premium < productConfig.exisitingPremium ? "premium" : "singlePremium"}
                                    component={FormPremium}
                                    min={checkSinglePremium() ? initProductConfig.premium >= premiumRange.min ? 0 : (premiumRange.min - initProductConfig.premium)/(productConfig.singlePremiumTerm - 1) : premiumRange.min}
                                    max={checkSinglePremium() ? productConfig.premium : premiumRange.max}
                                    label=""
                                    diff={100}
                                    isPrimary
                                    type="text"
                                    singleSplitPremium={checkSinglePremium()}
                                    onBlur={() => {
                                      setNextButtonStatus(false);
                                      setManualPremium(true);
                                    }}
                                    onButtonActionClick={() => {
                                      setNextButtonStatus(false);
                                      setManualPremium(true);
                                    }}
                                    onChange={(e, newVal, previousValue) => {
                                      if (
                                        !isResetForm &&
                                        Number(newVal) !== Number(previousValue)
                                      ) {
                                        setNewPremium(Number(newVal))
                                        if (Number(newVal) === 0){
                                          setSspThreeZeroOpen(true);
                                        } else {
                                          setSspThreeZeroOpen(false);
                                        }
                                        change('isEditPremium', true);
                                        setFrequencyOptions(
                                          productCodeCheck('FUTU', productConfig.productCode)
                                          ?
                                          getFrequencyOptionsForFuturaInforce(true,
                                            Number(newVal),
                                            policyConfig?.features?.currencyPremiumSize,
                                            currency,
                                            getAnniversaryDate(startDate),
                                            paymentFrequency,
                                            initProductConfig.paymentFrequency
                                            ):
                                          getFrequencyOptions(
                                            true,
                                            Number(newVal),
                                            policyConfig?.features?.currencyPremiumSize,
                                            currency,
                                          ),
                                        );
                                      } else {
                                        setResetForm(false);
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Collapse>
                          <Collapse in={sspThreeZeroOpen}>
                            <Box pt={0}>
                              <Grid container direction="row" spacing={1} alignItems="center">
                                <Grid item xs>
                                <Typography
                                style={{ fontSize: '1.18rem',color: theme.palette.error.main, paddingLeft: '58px' }}
                                >
                                <FormattedMessage
                                {...globalMessages.minLimit}
                                values={{ value: formatMonetaryValue(initProductConfig.premium >= premiumRange.min ? 0 : (premiumRange.min - initProductConfig.premium)/(productConfig.singlePremiumTerm - 1),true)}
                                }
                                />
                                </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Collapse>
                          {/* ########## END    :: EDIT PREMIUM ########## */}
                          <Grid item style={{ paddingBottom: '15px' }}></Grid>
                      </Grid>
                      }
                      { productConfig.additionalSinglePremium != 0
                      ? <Grid>
                          <Grid item style={{ paddingBottom: '5px' }}>
                            <Typography component="span" variant="h3">
                            <FormattedMessage {...messages.ASP} />
                            </Typography>
                          </Grid>
                          <Grid item style={{ paddingBottom: '10px' }}></Grid>  
                          <Grid item>
                            <span
                              id="primaryPremiumAmount"
                              style={{
                                color: 'inherit',
                                fontSize: '2.6rem',
                                lineHeight: 1,
                                fontWeight: 700,
                              }}
                            > 
                              {currency} {formatMonetaryValue(productConfig.additionalSinglePremium, true)} {' '}
                            </span>
                          </Grid>
                        </Grid>
                      : productConfig.additionalSinglePremium === 'Sufficient Premium'
                      ? <Grid>
                          <Grid item style={{ paddingBottom: '5px' }}>
                            <Typography component="span" variant="h3">
                            <FormattedMessage {...messages.ASP} />
                            </Typography>
                          </Grid>
                          <Grid item style={{ paddingBottom: '10px' }}></Grid>  
                          <Grid item>
                            <span
                              id="primaryPremiumAmount"
                              style={{
                                color: 'inherit',
                                fontSize: '2.6rem',
                                lineHeight: 1,
                                fontWeight: 700,
                              }}
                            > 
                              {currency} {formatMonetaryValue(0, true)} {' '}
                            </span>
                          </Grid>
                        </Grid>
                      : isMerged
                        ? <Grid>
                        <Grid item style={{ paddingBottom: '5px' }}>
                          <Typography component="span" variant="h3">
                          <FormattedMessage {...messages.ASP} />
                          </Typography>
                        </Grid>
                        <Grid item style={{ paddingBottom: '10px' }}></Grid>  
                        <Grid item>
                          <span
                            id="primaryPremiumAmount"
                            style={{
                              color: 'inherit',
                              fontSize: '2.6rem',
                              lineHeight: 1,
                              fontWeight: 700,
                            }}
                          > 
                            {currency} {formatMonetaryValue(productConfig.mergeASP, true)} {' '}
                          </span>
                        </Grid>
                      </Grid>
                        : <Grid></Grid>
                      }
                  </Grid>
              : <Grid></Grid>}
            </Grid>
            {/* ########## START  :: EDIT PREMIUM ########## */}
            <Collapse in={open}>
                          <Box pt={1}>
                            <Grid container direction="row" spacing={2} alignItems="center">
                            <Grid item xs={!checkSinglePremium() && !(productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0) ?4:6}>
                                <Field
                                  id= 'premium'
                                  name= 'premium'
                                  component={FormPremium}
                                  min={premiumRange.min}
                                  max={checkSinglePremium() ? productConfig.premium : premiumRange.max}
                                  label=""
                                  diff={100}
                                  isPrimary
                                  type="text"
                                  disabled={!productConfig.isRestart && isPolicyPaidUp }
                                  onBlur={() => {
                                    setNextButtonStatus(false);
                                    setManualPremium(true);
                                  }}
                                  onButtonActionClick={() => {
                                    setNextButtonStatus(false);
                                    setManualPremium(true);
                                  }}
                                  onChange={(e, newVal, previousValue) => {
                                    if (
                                      !isResetForm &&
                                      Number(newVal) !== Number(previousValue)
                                    ) {
                                      setNewPremium(Number(newVal));
                                      change('isEditPremium', true);
                                      setFrequencyOptions(
                                        productCodeCheck('FUTU', productConfig.productCode)
                                        ?
                                        getFrequencyOptionsForFuturaInforce(true,
                                          Number(newVal),
                                          policyConfig?.features?.currencyPremiumSize,
                                          currency,
                                          getAnniversaryDate(startDate),
                                          paymentFrequency,
                                          initProductConfig.paymentFrequency
                                          ):
                                        getFrequencyOptions(
                                          true,
                                          Number(newVal),
                                          policyConfig?.features?.currencyPremiumSize,
                                          currency,
                                        ),
                                      );
                                    } else {
                                      setResetForm(false);
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Collapse>
                        {/* ########## END    :: EDIT PREMIUM ########## */}
          </HeaderContent>
          <Grid container style={{ position: 'relative' }}>
          <Grid item xs={!checkSinglePremium() && !(productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0) ?4:6}>
              <Field component="input" type="hidden" name="isGetPremium" />
              <Field
                component="input"
                type="hidden"
                name="isCalculateSustainability"
              />
              <Button
                disabled={!(canEditPremium && isASPWOL) || (premium < productConfig.exisitingPremium && premium > 0 && productConfig.isMerged === false) || isPartialWithdrawal || !productConfig.isRestart || isPolicyWholeOfLife}
                style={{
                  backgroundColor: (canEditPremium && isASPWOL) && !(premium < productConfig.exisitingPremium && premium > 0 && productConfig.isMerged === false) && !isPartialWithdrawal && productConfig.isRestart && !isPolicyWholeOfLife ? '#4066B3' : '#BFBBAA',
                  color: 'white',
                  width: '100%',
                  // padding: '20px 0',
                  height: '100%',
                  margin: 'auto',
                }}
                variant="contained"
                onClick={async () => {
                  await change('isGetPremium', true);
                  await change('isCalculateSustainability', false);
                  await change('policyCoverTerm', 'WholeOfLife');
                  await change('mergeSustainability', false);
                  productConfig.mergeSustainability = false;
                  resetSustainabilityYears('WholeOfLife');
                  await change('rpAsp', 0);
                  setSspThreeOpen(false);
                  setSspTwoOpen(false);
                  setOpen(false);
                  setSspTwoZeroOpen(false);
                  setSspThreeZeroOpen(false);
                  handleSubmit();
                  if (canEditPremium) {
                    if (premium !== lastPremium) {
                      setOpen(false);
                      setLastPremium(premium);
                    }
                  }

                  if (!isCoveragesError) {
                    setEditData({ editIndex: 0, editInsured: 0 });
                    setEditType(0);
                  }
                }}
              >
                {
                  checkSinglePremium() || (productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0)
                  ? <FormattedMessage {...messages.getASPBaseWOL} />
                  : <FormattedMessage {...messages.getPremiumBaseWOL} />
                }
              </Button>
            </Grid>
            {!checkSinglePremium() && !(productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0)? <>
              <VerticalLine33 color="#023694" />
            <Grid item xs={4}>

            <Button
                disabled={singlePremium > 0 || !isASPWOL || isPartialWithdrawal || isPolicyWholeOfLife || ((productConfig.policyStatus == 'L' || productConfig.policyStatus == 'C') && isChooseToPayAllDues != true)}
                style={{
                  backgroundColor: singlePremium > 0 || !isASPWOL || isPartialWithdrawal || isPolicyWholeOfLife || ((productConfig.policyStatus == 'L' || productConfig.policyStatus == 'C') && isChooseToPayAllDues != true)? '#BFBBAA' : '#4066B3',
                  color: 'white',
                  width: '100%',
                  // padding: '20px 0',
                  height: '100%',
                  margin: 'auto',
                }}
                variant="contained"
                onClick={async () => {
                  setASPManual(false)
                  await change('isGetPremium', true);
                  await change('isCalculateSustainability', false);
                  await change('policyCoverTerm', 'WholeOfLife');
                  await change('mergeSustainability', false);
                  await change('rpAsp', 1);
                  
                  productConfig.mergeSustainability = false;
                  //resetSustainabilityYears('WholeOfLife');
                  setSspThreeOpen(false);
                  setSspTwoOpen(false);
                  setOpen(false);
                  setSspTwoZeroOpen(false);
                  setSspThreeZeroOpen(false);
                  handleSubmit();
                  if (canEditPremium) {
                    if (premium !== lastPremium) {
                      setOpen(false);
                      setLastPremium(premium);
                    }
                  }

                  if (!isCoveragesError) {
                    setEditData({ editIndex: 0, editInsured: 0 });
                    setEditType(0);
                  }
                }}
              >
                {
                  checkSinglePremium() || (productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0)
                  ? <FormattedMessage {...messages.getASPBaseWOL} />
                  : <FormattedMessage {...messages.getASPBaseWOL} />
                }
              </Button>
            </Grid>
            <VerticalLine66 color="#023694" />
            </>:
            <><VerticalLine color="#023694" /></>}
            
            <Grid item xs={!checkSinglePremium() && !(productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0) ?4:6}>
              <Button
                style={{
                  backgroundColor: '#4066B3',
                  color: 'white',
                  width: '100%',
                  // padding: '20px 0',
                  height: '100%',
                  margin: 'auto',
                }}
                variant="contained"
                onClick={async e => {
                  await change('isGetPremium', false);
                  await change('isCalculateSustainability', true);
                  await change('mergeSustainability', true);
                  productConfig.mergeSustainability = true;
                  await change('policyCoverTerm', 'CustomPremium');
                  handleSubmit();
                  setSspThreeOpen(false);
                  setSspTwoOpen(false);
                  setOpen(false);
                  setSspTwoZeroOpen(false);
                  setSspThreeZeroOpen(false);
                  if (isSmokerChanged()) // smoker status of lives are changed, 'save this quote' should be enabled after clicking calculate sustain
                  {
                    setNextButtonStatus(true);
                  }; 
                  if (!isCoveragesError) {
                    setEditData({ editIndex: 0, editInsured: 0 });
                    setEditType(0);
                  }
                  if (formValues.isAgeWOL) {
                    setPolicyWholeOfLife(true)
                  } else {
                    setPolicyWholeOfLife(false)
                  }
                }}
              >
                <FormattedMessage {...messages.calculateSustain} />
              </Button>
            </Grid>
          </Grid>

          {/* ======== Paidup =========  add radio button to choose or not restart option*/} 
          { isPolicyPaidUp &&
          <BottomRow
            container
            isLock={isLockQuoteForm}
            theme={theme}
            style={{ padding: '16px 8px 16px 24px' }}
          >
          <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <Grid container alignItems="center" justify="center">
                  <CheckCircle
                    style={
                      isLockQuoteForm
                        ? { color: theme.palette.grey[400] }
                        : { color: theme.palette.success.main }
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs={9}>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: '900',
                    color: isLockQuoteForm
                      ? theme.palette.grey[400]
                      : theme.palette.common.black,
                  }}
                >
                  <FormattedMessage {...messages.isRestart} />
                </Typography>
              </Grid>
              <Grid item xs={3}>
              <Field
                name="isRestart"
                disabled={false}
                component={FormBooleanOption}
                onChange={async () => {
                  setNextButtonStatus(false);
                  productConfig.isRestart = !productConfig.isRestart;
                  productConfig.isReinstate = !productConfig.isReinstate;
                  
                  if(productConfig.isRestart == true){
                    await change('premium',  productConfig.oldPremium);  
                  } else{
                    if(premium != 0){
                      productConfig.oldPremium = premium;
                    }
                    await change('premium',  0);
                    getPaymentFrequencyOptions();
                    change('restartFrom', firstMissedDue);
                  }
                }}
                type="text"
                margin="normal"
                fullWidth
              />
                </Grid>
                <div>
                <Typography
                      variant="body1"
                      style={{
                        fontWeight: '900',
                        color: isLockQuoteForm
                          ? theme.palette.grey[400]
                          : theme.palette.common.black,
                      }}
                    >
                    </Typography>   
                </div>
            </Grid>
          </BottomRow> }
          {/*=== End of Paidup  ===*/}
          {/* ======== Restart From ========= LAAV_RI -> add drop down and add the premium payment due date in month and year format eg. March 2023*/} 
          
          { (productConfig.policyStatus == 'L' || productConfig.policyStatus == 'C') &&
          <BottomRow
            container
            isLock={isLockQuoteForm}
            theme={theme}
            style={{ padding: '16px 8px 16px 24px' }}
          >
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <Grid container alignItems="center" justify="center">
                  <CheckCircle
                    style={
                      isLockQuoteForm
                        ? { color: theme.palette.grey[400] }
                        : { color: theme.palette.success.main }
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: '900',
                    color: isLockQuoteForm
                      ? theme.palette.grey[400]
                      : theme.palette.common.black,
                  }}
                >
                  <FormattedMessage {...messages.restartFrom} />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                  <Field
                    shrink
                    name="restartFrom"
                    fullWidth
                    component={FormSelect}
                    type="text"
                    options={restartFromOptions}
                    disabled={!productConfig.isRestart}
                    onChange={handleChange}
                    margin="normal"
                    variant="standard"
                   
                  />
                </Grid>
                <div>
                <Typography
                      variant="body1"
                      style={{
                        fontWeight: '900',
                        color: isLockQuoteForm
                          ? theme.palette.grey[400]
                          : theme.palette.common.black,
                      }}
                    >
                    </Typography>   
                </div>
            </Grid>
            
          </BottomRow>
          }
          {/*= ===== Premium Payment Term ====== */}
          <BottomRow
            container
            isLock={isLockQuoteForm}
            theme={theme}
            style={{ padding: '16px 8px 16px 24px' }}
          >
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <Grid container alignItems="center" justify="center">
                  <CheckCircle
                    style={
                      isLockQuoteForm
                        ? { color: theme.palette.grey[400] }
                        : { color: theme.palette.success.main }
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: '900',
                    color: isLockQuoteForm
                      ? theme.palette.grey[400]
                      : theme.palette.common.black,
                  }}
                >
                  <FormattedMessage {...messages.premiumTerm} />
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Grid
                  container
                  spacing={1}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item xs={8}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: '900',
                        color: isLockQuoteForm
                          ? theme.palette.grey[400]
                          : theme.palette.common.black,
                      }}
                    >
                      {checkSinglePremium()
                      ? `${productConfig.singlePremiumTerm}  Years`
                      : productConfig.singlePremiumTerm === 1
                      ? `${productConfig.singlePremiumTerm}  Year`
                      : termDisplay
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
				            {/* Premium Term cannot be changed during premium holiday period and when partial withdrawal is enabled */}
                    {/* Premium Term cannot be changed when advance premium payment is done */}
                    {/* LAAV_RI - isInNAPRAPPeriod -> if any of the slice is in NAP/RAP period, then premium term should be made non editable, so diabling pencil icon based on the newly added flag isInNAPRAPPeriod */}
                    {(!productConfig.isPremiumHoliday)  && (!productConfig.isAdvancePremiumPaid) && !isInNAPRAPPeriod && !isSingePaymentFrequency && !isFutu5 && !isPartialWithdrawal && productConfig.isRestart /*&& !isPolicyPaidUp*/ && ( 
                      <IconButton onClick={() => setEditType(1)}>
                        <img
                          style={{ height: '1.6rem' }}
                          src={IconPencilBlue}
                          alt=""
                        />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Collapse in={editType === 1}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs={7}>
                  <Field
                    shrink
                    name="term"
                    fullWidth
                    disabled={!Number.isInteger(Number(term))}
                    component={
                      Number.isInteger(Number(term))
                        ? FormNumberField
                        : FormTextField
                    }
                    type="text"
                    onChange={(e, newVal, previousValue) => {
                      setNextButtonStatus(false);
                      // if (
                      //   Number(newVal) >= minTerm &&
                      //   Number(newVal) <= maxTerm &&
                      //   !isEditPremium
                      // ) {
                      //   change(
                      //     'premium',
                      //     getInitPremiumByFrequency(
                      //       initProductConfig.regularPremiumSlices,
                      //       initProductConfig.paymentFrequency,
                      //     ),
                      //   );
                      // }
                      if (
                        !isResetForm &&
                        Number(newVal) != Number(previousValue)
                      ) {
                        setIsPartialWithdrawal(false);
                        setIsInverseTransactionOfPartialWithdrawal(true);
                        change('isEditTerm', true);
                      } else {
                        setResetForm(false);
                      }
                    }}
                    margin="normal"
                    variant="standard"
                  />
                </Grid>

                <Grid item xs={2}></Grid>
              </Grid>
            </Collapse>
          </BottomRow>

          {/*= ===== Premium Payment Frequency ====== */}
          <BottomRow
            container
            isLock={isLockQuoteForm}
            theme={theme}
            style={{ padding: '16px 8px 16px 24px' }}
          >
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <Grid container alignItems="center" justify="center">
                  <CheckCircle
                    style={
                      isLockQuoteForm
                        ? { color: theme.palette.grey[400] }
                        : { color: theme.palette.success.main }
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: '900',
                    color: isLockQuoteForm
                      ? theme.palette.grey[400]
                      : theme.palette.common.black,
                  }}
                >
                  <FormattedMessage {...messages.premiumPaymentFrequency} />
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item xs={8}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: '900',
                        color: isLockQuoteForm
                          ? theme.palette.grey[400]
                          : theme.palette.common.black,
                      }}
                    >
                      {paymentFrequency}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    {/*Premium Payment frequency cannot be changed during premium holiday period and when partial withdrawal is enabled*/}
                    {(!productConfig.isPremiumHoliday) &&
                    (!productConfig.isAdvancePremiumPaid) &&  // premium payment frequency cannot be changed when advance premium payment is done
                          productConfig.regularPremiumSliceLength !=0 && 
                              !isPartialWithdrawal && 
                                (productConfig.policyStatus == 'A' && (productConfig.premiumDueData.length == 0 || (productConfig.premiumDueData.length > 0 && !productConfig.isOverDuePremium)) || // for inforce policies, frequency cannot be edited if premium due data is received but not over due
                                ((productConfig.policyStatus == 'L' || productConfig.policyStatus == 'C') && isRestartMonthInFrequency)) && // for LAPSED / paid-up Policies, premium frequency edit icon should be enabled if restart month is available in the frequency options drop down
                                productConfig.isRestart &&
                                (
                      <IconButton onClick={() => setEditType(2)}>
                        <img
                          style={{ height: '1.6rem' }}
                          src={IconPencilBlue}
                          alt=""
                        />
                      </IconButton>
                     )} 
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Collapse in={editType === 2}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs={7}>
                {(!productConfig.isPremiumHoliday) && 
                          productConfig.regularPremiumSliceLength !=0 && 
                              !isPartialWithdrawal && 
                              (productConfig.policyStatus == 'A' && (productConfig.premiumDueData.length == 0 || (productConfig.premiumDueData.length > 0 && !productConfig.isOverDuePremium)) || // for inforce policies, frequency cannot be edited if premium due data is received but not over due
                                ((productConfig.policyStatus == 'L' || productConfig.policyStatus == 'C') && isRestartMonthInFrequency)) && // for LAPSED / paid-up Policies, premium frequency drop down should be enabled if restart month is available in the frequency options drop down
                                productConfig.isRestart &&
                                (
                                  <Field
                                  shrink
                                  name="paymentFrequency"
                                  fullWidth
                                  component={FormSelect}
                                  type="text"
                                  options={frequencyOptions}
                                  margin="normal"
                                  variant="standard"
                                  onChange={async (event, newValue, previousValue, name) => {
                                    await change('premium',  getPremiumOfNewFrequency(premium,previousValue,newValue));
                                    setIsPartialWithdrawal(false);
                                    setIsInverseTransactionOfPartialWithdrawal(true);
                                    setNextButtonStatus(false);
                                  }}
                                />
                     )} 
                 
                </Grid>

                <Grid item xs={2}>
                </Grid>
              </Grid>
            </Collapse>
          </BottomRow>

          {/*= ===== Assumed Growth rate ====== */}
          <BottomRow
            isLock={isLockQuoteForm}
            container
            theme={theme}
            style={{ padding: '16px 8px 16px 24px' }}
          >
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <Grid container alignItems="center" justify="center">
                  <CheckCircle
                    style={
                      isLockQuoteForm
                        ? { color: theme.palette.grey[400] }
                        : { color: theme.palette.success.main }
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: '900',
                    color: isLockQuoteForm
                      ? theme.palette.grey[400]
                      : theme.palette.common.black,
                  }}
                >
                  <FormattedMessage {...messages.assumedGrowthRate} />
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item xs={8}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: '900',
                        color: isLockQuoteForm
                          ? theme.palette.grey[400]
                          : theme.palette.common.black,
                      }}
                    >
                      {`${growthRate}%`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                  {!isPartialWithdrawal &&
                    <IconButton onClick={() => setEditType(3)}>
                      <img
                        style={{ height: '1.6rem' }}
                        src={IconPencilBlue}
                        alt=""
                      /> 
                    </IconButton> }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Collapse in={editType === 3}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs={7}>
                  <Field
                    name="growthRate"
                    component={FormSlider}
                    min={MIN_GROWTH_RATE}
                    max={MAX_GROWTH_RATE}
                    diff={0.5}
                    isPrimary={false}
                    type="text"
                    onChange={() => {
                      setIsPartialWithdrawal(false);
                      setIsInverseTransactionOfPartialWithdrawal(true);
                      setNextButtonStatus(false);
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Field
                    name="growthRate"
                    fullWidth
                    component={FormNumberField}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    onChange={() => {
                      setIsPartialWithdrawal(false);
                      setIsInverseTransactionOfPartialWithdrawal(true);
                      setNextButtonStatus(false);
                    }}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </BottomRow>

          {/* ===== Additional Single Premium ===== */}

          <BottomRow
            container
            isLock={isLockQuoteForm}
            theme={theme}
            style={{ padding: '16px 8px 16px 24px' }}
          >
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <Grid container alignItems="center" justify="center">
                  {singlePremium === 0 || productConfig.additionalSinglePremium === 0 ? (
                    <CheckCircle
                      style={{ color: theme.palette.success.main }}
                    />
                  ) : (
                    <Cancel style={{ color: theme.palette.grey[400] }} />
                  )}
                </Grid>
              </Grid>

              <Grid item xs>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: '900',
                    color: isLockQuoteForm
                      ? theme.palette.grey[400]
                      : theme.palette.common.black,
                  }}
                >
                  <FormattedMessage {...messages.additionalSinglePremium} />
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item xs={8}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: '900',
                        color: isLockQuoteForm
                          ? theme.palette.grey[400]
                          : theme.palette.common.black,
                      }}
                    > 
                      {
                        (checkSinglePremium() || (productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0))
                        ? productConfig.singlePremium === 0 || productConfig.singlePremium === '0'
                          ? formatMonetaryValue(productConfig.additionalSinglePremium, true)
                          : formatMonetaryValue(productConfig.singlePremium, false)
                        : formatMonetaryValue(singlePremium, false)
                      }
                    </Typography>
                  </Grid> 
                  {/* // ASP enabled only when customer chooses to pay all dues , chooses date from first missed date */}
                  {(((productConfig.policyStatus == 'L' || productConfig.policyStatus == 'C') && isChooseToPayAllDues == true) || (productConfig.policyStatus == 'A')) ?
                     ( (checkSinglePremium() && productConfig.additionalSinglePremium === 0) 
                      ? <Grid item xs={4}>
                        {!isPartialWithdrawal && isASPManual &&
                          <IconButton onClick={() => setEditType(4)}>
                            <img
                              style={{ height: '1.6rem' }}
                              src={IconPencilBlue}
                              alt=""
                            />
                          </IconButton>
                        }
                        </Grid>
                      : (productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0)
                        ? productConfig.additionalSinglePremium === 0
                          ? <Grid item xs={4}>
                            {!isPartialWithdrawal && isASPManual &&  
                              <IconButton onClick={() => setEditType(4)}>
                                <img
                                  style={{ height: '1.6rem' }}
                                  src={IconPencilBlue}
                                  alt=""
                                />
                              </IconButton>
                            }
                            </Grid>
                            : <Grid item xs={4}></Grid>
                        : checkSinglePremium()
                        ? <Grid item xs={4}></Grid>
                        : <Grid item xs={4}>
                          {!isPartialWithdrawal && isASPManual &&   
                            <IconButton onClick={() => setEditType(4)}>
                              <img
                                style={{ height: '1.6rem' }}
                                src={IconPencilBlue}
                                alt=""
                              />
                            </IconButton>
                          }
                          </Grid>
                      ):
                      (
                        <Grid item xs={4}></Grid>
                      )}
                </Grid>
              </Grid>
            </Grid>

            <Collapse in={editType === 4}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="flex-end"
              >
                <Grid item xs={4}>
                  <Field
                    shrink
                    name="singlePremium"
                    fullWidth
                    component={FormNumberField}
                    type="text"
                    margin="normal"
                    variant="standard"
                    disabled={!(((productConfig.policyStatus == 'L' || productConfig.policyStatus == 'C') && isChooseToPayAllDues == true) || (productConfig.policyStatus == 'A'))}
                    onChange={() => {
                      console.log('On Change')
                      console.log('Cond 1', singlePremium > 0)
                      console.log('Check Single Premium', checkSinglePremium())
                      console.log('Cond 2',(productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0))
                      setNextButtonStatus(false);
                      setIsPartialWithdrawal(false);
                      setIsInverseTransactionOfPartialWithdrawal(true);
                      if (singlePremium > 0 && (checkSinglePremium() || (productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0))){
                        setIsASPWOL(false);
                        setASPManual(true);
                      } else {
                        setIsASPWOL(true);
                        setASPManual(false);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </BottomRow>
          {/* ===== Partial Withdrawal ===== */}
          <BottomRow
            container
            isLock={isLockQuoteForm}
            theme={theme}
            style={{ padding: '16px 8px 16px 24px' }}
          >
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <Grid container alignItems="center" justify="center">
                  {partialWithdrawal > 0 ? (
                    <CheckCircle
                      style={{ color: theme.palette.success.main }}
                    />
                  ) : (
                    <Cancel style={{ color: theme.palette.grey[400] }} />
                  )}
                </Grid>
              </Grid>

              <Grid item xs>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: '900',
                    color: isLockQuoteForm
                      ? theme.palette.grey[400]
                      : theme.palette.common.black,
                  }}
                >
                  <FormattedMessage {...messages.partialWithdrawal} />
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item xs={8}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: '900',
                        color: isLockQuoteForm
                          ? theme.palette.grey[400]
                          : theme.palette.common.black,
                      }}
                    >
                      {partialWithdrawal > 0 ? partialWithdrawal : 0}
                    </Typography>
                  </Grid>
                  {/* partial withdrawal should be disabled for lapsed policies */}
                  {
                  ((isPolicySatisfyMinPartialWithdrawal() && 
                  (!productConfig.isPremiumHoliday) && 
                  ((isPartialWithdrawal === false && isInverseTransactionOfPartialWithdrawal === false) || isPartialWithdrawal) && 
                  (!isSmokerChanged()) && 
                  (productConfig.policyStatus != 'L') &&
                  !isReinstatementWithPaidLapsePolicy() && 
                  !(productConfig.isRestart && productConfig.policyStatus == 'C') &&
                 !isLifeInsuredChanged()) 
                 || // if policy is inforce and advance premium paid, then partial withdrawal should be enabled
                 ((productConfig.policyStatus == 'A' && productConfig.isAdvancePremiumPaid)))  
                 ? 
                 <Grid item xs={4}>
                          <IconButton onClick={() => setEditType(5)}>
                            <img
                              style={{ height: '1.6rem' }}
                              src={IconPencilBlue}
                              alt=""
                            />
                          </IconButton>
                  </Grid>
                  :
                  <Grid item xs={4}></Grid>
                  }
                </Grid>
              </Grid>
            </Grid>

            <Collapse in={editType === 5}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="flex-end"
              >
                <Grid item xs={4}>
                  <Field
                    shrink
                    name="partialWithdrawal"
                    fullWidth
                    component={FormNumberField}
                    type="text"
                    margin="normal"
                    variant="standard"
                    onChange={(value) => {
                      setNextButtonStatus(false);
                      if (value > 0){
                        setIsInverseTransactionOfPartialWithdrawal(false);
                        setIsPartialWithdrawal(true);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </BottomRow>
		  
          {/* ============= Premium Indexation ========= */}
          {isPrmIndexApplicable && 
          <BottomRow
            container
            isLock={isLockQuoteForm}
            theme={theme}
            style={{ padding: '16px 8px 16px 24px' }}
          >
            <Grid container alignItems="center" spacing={1} wrap="nowrap" >
              <Grid item >
                <Grid container alignItems="center" justify="center" >
                  {isPrmIndexation ? (
                    <CheckCircle
                      style={{ color: theme.palette.success.main }}
                    />
                  ) : (
                    <Cancel style={{ color: theme.palette.grey[400] }} />
                  )}
                </Grid>
              </Grid>

              <Grid item xs >
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: '900',
                    color: isLockQuoteForm
                      ? theme.palette.grey[400]
                      : theme.palette.common.black,
                  }}
                >
                  <FormattedMessage {...messages.premiumIndexation} />
                </Typography>
              </Grid>
              <Grid item xs={3}  alignItems="center" >
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: '900',
                        color: isLockQuoteForm
                          ? theme.palette.grey[400]
                          : theme.palette.common.black,
                      }}
                    >
                       {isPrmIndexation ? 'Yes' : 'No' }
                    </Typography>
                  </Grid>
            </Grid>
          </BottomRow>}

          <Grid
            container
            direction="row"
            spacing={1}
            justify="space-between"
            alignItems="center"
            style={{
              marginBottom: theme.spacing(1),
              marginTop: theme.spacing(1),
            }}
          >
            <Grid item xs />
            <Grid item xs={3}>
              <Typography
                color="primary"
                variant="h3"
                style={{ marginLeft: theme.spacing(0.5) }}
              >
                {lifeAssured.insuredOne.firstName}
              </Typography>
            </Grid>
            {lifeAssured.isInsuredTwo ? (
              <Grid item xs={3}>
                <Typography
                  color="primary"
                  variant="h3"
                  style={{ marginLeft: theme.spacing(0.5) }}
                >
                  {lifeAssured.insuredTwo.firstName}
                </Typography>
              </Grid>
            ) : null}
          </Grid>

          {coverages.length > 0 ? (
            <FieldArray
              name="coverages"
              component={Benefits}
              theme={theme}
              coverages={coverages}
              inactive={open}
              isResetForm={isResetForm}
              isCoveragesError={isCoveragesError}
              productConfig={productConfig}
              policyConfig={policyConfig}
              currency={currency}
              options={options}
              lifeBasis={lifeAssured.lifeBasis}
              lifeAssured={lifeAssured}
              startDate={startDate}
              change={change}
              editData={editData}
              changeEditData={val => {
                setEditData(val);
              }}
              onCoverageChange={() => {
                setIsPartialWithdrawal(false);
                setIsInverseTransactionOfPartialWithdrawal(true);
                setNextButtonStatus(false);
                setResetForm(false);
              }}
              isPartialWithdrawal={isPartialWithdrawal}
              isInsuredTwo={lifeAssured.isInsuredTwo}
            />
          ) : null}
        </Card>
      </form>
    </>
  );
}

QuoteForm.propTypes = {
  ...InjectedFormProps,
  lifeAssured: PropTypes.object,
  errorCoverages: PropTypes.object,
  options: PropTypes.object,
  policyConfig: PropTypes.object,
  onUpdateValues: PropTypes.func,
  startDate: PropTypes.string,
  growthRate: PropTypes.number,
  term: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offerCode: PropTypes.string,
  currency: PropTypes.string,
  paymentFrequency: PropTypes.string,
  restartFrom: PropTypes.string,
  specialTermsFlag: PropTypes.bool,
  premium: PropTypes.number,
  formValues: PropTypes.object,
  product: PropTypes.object,
  coverages: PropTypes.array,
  offerCodeData: PropTypes.object,
  resetGrowthRate: PropTypes.func,
  resetSustainabilityYears: PropTypes.func,
};

const withForm = reduxForm({
  form: 'quoteForm',
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  touchOnBlur: true,
})(QuoteForm);

const selector = formValueSelector('quoteForm');
const syncErrorsSelector = getFormSyncErrors('quoteForm');

const mapStateToProps = state => {
  const currency = selector(state, 'currency');
  const partialWithdrawal = selector(state, 'partialWithdrawal');
  const growthRate = selector(state, 'growthRate');
  const specialTermsFlag = selector(state, 'specialTermsFlag');
  const startDate = selector(state, 'startDate');
  const term = selector(state, 'term');
  const offerCode = selector(state, 'offerCode');
  const premium = selector(state, 'premium');
  const paymentFrequency = selector(state, 'paymentFrequency');
  const restartFrom =  selector(state, 'restartFrom');
  const singlePremium = selector(state, 'singlePremium');
  const coverages = selector(state, 'coverages');
  const isGetPremium = selector(state, 'isGetPremium');
  const isEditPremium = selector(state, 'isEditPremium');
  const formValues = getFormValues('quoteForm')(state);
  let errorCoverages = syncErrorsSelector(state, 'coverages');
  const policyConfig = makeSelectPolicyConfig()(state);
  const initProductConfig = makeSelectInitProductConfig()(state);
  const minTerm = getMinValidTerm(7, startDate); //privide the minimum 7 year insted of previously gvien one
  const maxTerm = policyConfig?.features?.policyTerm.maxVanishingPremiumTerm;
  const isValidTerm = checkValidTerm(term, maxTerm, startDate);
  if (errorCoverages instanceof Map) {
    errorCoverages = errorCoverages.toJS();
  }
  return {
    premium,
    currency,
    term,
    offerCode,
    startDate,
    growthRate,
    paymentFrequency,
    specialTermsFlag,
    singlePremium,
    coverages: coverages?.toJS() || [],
    formValues: formValues?.toJS() || {},
    errorCoverages,
    isValidTerm,
    maxTerm,
    minTerm,
    isGetPremium,
    isEditPremium,
    partialWithdrawal,
    restartFrom,
  };
};

const mapDispatchToProps = dispatch => ({
  resetSustainabilityYears: value =>
    dispatch(setSustainabilityYearsAction(value)),
  resetGrowthRate: number => dispatch(setGrowthRateAction(number)),
  setPolicyTerm: value => dispatch(setPolicyTermAction(value)),
  resetProductConfigs: data => dispatch(resetProductConfigsAction(data)),
  setManualPremium: value => dispatch(setManualPremiumAction(value)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(withForm);

export default withConnect;